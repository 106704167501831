import React from 'react'
import UserList from './components/UserList'
import { TranslateContext } from '../../../context/TranslateContext'
import { GlobalContext } from '../../../context/GlobalContext'
import { Button, Stack } from '@mui/material'
import AddUser from './components/AddUser'

const Users = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [userData, setUserData] = React.useState([])
    const [userLeverl, setUserLevel] = React.useState()

    async function getUsers() {
        const res = await hitAxios({
            path: "/api/admin/get_all_users",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setUserData(res.data.data)
        }
    }

    React.useEffect(() => {
        getUsers()
    }, [])
    return (
        <div>
            <Stack direction={'row'} justifyContent={'flex-end'}>
                <AddUser
                    userLeverl={userLeverl}
                    getUsers={getUsers}
                    userData={userData}
                    data={data}
                    hitAxios={hitAxios}
                />
            </Stack>
            <UserList
                userLeverl={userLeverl}
                getUsers={getUsers}
                userData={userData}
                data={data}
                hitAxios={hitAxios}
            />
        </div>
    )
}

export default Users