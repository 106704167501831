import { Box, CardMedia } from '@mui/material';
import React from 'react';
import Slider from "react-slick";

const SliderComp = ({ data, slider }) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'notes-slider',
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Box  >
            <Slider {...settings}>
                {slider.map((i, key) => {
                    return (
                        <div key={key}>
                            <CardMedia
                                sx={{ height: 400, width: "100%" }}
                                image={`/media/${i}`}
                                component={'img'}
                            />
                        </div>
                    )
                })}
            </Slider>
        </Box>
    );
};

export default SliderComp;
