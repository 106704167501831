import { Avatar, Box, Grid, Stack, Typography, Zoom } from '@mui/material'
import React from 'react'
import { Campaign, HelpOutline, Speed, SupportAgent, WorkspacePremium } from '@mui/icons-material'
import CheckLevel from './CheckLevel'
import { GlobalContext } from '../../../context/GlobalContext'
import ShowAnnoun from './ShowAnnoun'
import HelpComp from './HelpComp'
import SupportPage from '../../../SupportPage'

const HeroButton = ({ data, set }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        anoun: false,
        levelDialog: false
    })
    const [user, setUser] = React.useState({})

    async function getUser() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }

    React.useEffect(() => {
        getUser()
    }, [])

    return (
        <div>

            <CheckLevel
                user={user}
                data={data}
                state={state}
                setState={setState}
            />

            <ShowAnnoun
                user={user}
                data={data}
                state={state}
                setState={setState}
                hitAxios={hitAxios}
            />

            <HelpComp
                user={user}
                data={data}
                state={state}
                setState={setState}
                hitAxios={hitAxios}
            />

            <Box
                sx={{ borderTopRightRadius: 20, borderTopLeftRadius: 20 }}
                bgcolor={"white"} p={4}>
                <Zoom in={true} timeout={1000}>
                    <Stack sx={{ overflowX: 'scroll' }} justifyContent={'space-between'} spacing={1} direction={'row'}>
                        <Stack
                            onClick={() => setState({ ...state, levelDialog: true })}
                            sx={{ cursor: "pointer" }}
                            width={'25%'} alignItems={'center'} direction={'column'} spacing={1}>
                            {/* <Speed
                                sx={{
                                    height: 50,
                                    width: 50
                                }}
                            /> */}

                            <Avatar
                                src='/assets/9587085.png'
                            />
                            <Typography align='center' variant='body2'>{data.level}</Typography>
                        </Stack>
                        <Stack
                            onClick={() => setState({ ...state, anoun: true })}
                            sx={{ cursor: "pointer" }}
                            width={'25%'} alignItems={'center'} direction={'column'} spacing={1}>
                            {/* <Campaign
                                sx={{
                                    height: 50,
                                    width: 50
                                }}
                            /> */}
                            <Avatar
                                src='/assets/3437346.png'
                            />

                            <Typography align='center' variant='body2'>{data.announcement}</Typography>
                        </Stack>
                        <Stack
                            onClick={() => setState({ ...state, helpDialog: true })}
                            sx={{ cursor: "pointer" }}
                            width={'25%'} alignItems={'center'} direction={'column'} spacing={1}>
                            {/* <HelpOutline
                                sx={{
                                    height: 50,
                                    width: 50
                                }}
                            /> */}
                            <Avatar
                                src='/assets/3728740.png'
                            />

                            <Typography align='center' variant='body2'>{data.help}</Typography>
                        </Stack>

                        <SupportPage
                            data={data}
                            set={set}
                        />


                        <Stack
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                CON?.setData({ ...CON.data, supportPage: true })
                            }}
                            width={'25%'} alignItems={'center'} direction={'column'} spacing={1}>
                            <Avatar
                                src='/assets/2706950.png'
                            />

                            <Typography align='center' variant='body2'>{data.support}</Typography>
                        </Stack>
                    </Stack>
                </Zoom>
            </Box>
        </div>
    )
}

export default HeroButton