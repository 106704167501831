import { Close } from '@mui/icons-material'
import { Box, Button, Container, Dialog, Divider, IconButton, Stack, Typography, Chip, LinearProgress } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../../components/TextFieldNew'
import moment from 'moment'
import TabComp from './task/TabComp'
import { GlobalContext } from '../../../../context/GlobalContext'

const EditUser = ({ hitAxios, state, setState, data, userData, getUsers, userLeverl }) => {
    const CON = React.useContext(GlobalContext)

    async function updateUser() {
        const res = await hitAxios({
            path: "/api/admin/update_user_balance",
            post: true,
            admin: true,
            obj: state.dialogData
        })
        if (res.data.success) {
            getUsers()
        }
    }

    async function upgradeUser(uid, level) {
        const res = await hitAxios({
            path: "/api/admin/change_user_level",
            post: true,
            admin: true,
            obj: {
                level,
                uid
            }
        })
        if (res.data.success) {
            setState({ ...state, dialogData: { ...state.dialogData, user_type: level } })
            getUsers()
        }
    }

    const userLevel = ["NORMAL", "VIP1", "VIP2", "VIP3", "VIP4"]

    return (
        <Dialog
            fullScreen
            open={state.dialog}>
            <Box p={2} bgcolor={'action.hover'} >
                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })} >
                        <Close />
                    </IconButton>
                    <Typography>
                        {data.close}
                    </Typography>
                </Stack>
            </Box>

            {CON.data?.loading &&
                <LinearProgress />}

            <Box mt={2} >
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                fullWidth
                                disabled
                                label="Username"
                                value={state?.dialogData?.username}
                            />
                            <TextFieldNew
                                fullWidth
                                disabled
                                label="Email"
                                value={state?.dialogData?.email}
                            />
                        </Stack>

                        <Stack alignItems={"center"} direction={'row'} spacing={2}>
                            <Box width={'50%'}>
                                <TextFieldNew
                                    fullWidth
                                    onChange={(e) => setState({ ...state, dialogData: { ...state.dialogData, phone: e.target.value } })}
                                    label="Phone"
                                    value={state?.dialogData?.phone}
                                />
                            </Box>

                            <Box width={'50%'}>
                                <Stack spacing={1} justifyContent={'space-between'} direction={'row'} sx={{ overFlowX: "scroll" }}>
                                    {userLevel.map((i, key) => {
                                        return (
                                            <Chip
                                                onClick={() => upgradeUser(state?.dialogData?.uid, i)}
                                                sx={{ width: "100%" }}
                                                label={i}
                                                variant={i === state?.dialogData?.user_type ? 'filled' : 'outlined'}
                                                key={key}
                                            />
                                        )
                                    })}
                                </Stack>
                            </Box>
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                fullWidth
                                onChange={(e) => setState({ ...state, dialogData: { ...state.dialogData, balance: e.target.value } })}
                                label="Balance"
                                value={state?.dialogData?.balance}
                            />
                            <TextFieldNew
                                disabled
                                fullWidth
                                label="Created on"
                                value={moment(state?.dialogData?.createdAt).format("DD MMM YY | hh:mmA")}
                            />
                        </Stack>

                        <TextFieldNew
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    dialogData: {
                                        ...state.dialogData,
                                        newPas: e.target.value
                                    }
                                })
                            }
                            fullWidth
                            label="New Password"
                            helperText="Leave it if you dont want to change password"
                        />

                        <Button
                            onClick={updateUser}
                            variant='contained' >
                            Update
                        </Button>

                        <Divider />

                        <TabComp
                            dialogData={state?.dialogData}
                            hitAxios={hitAxios}
                            data={data}
                        />
                    </Stack>
                </Container>
            </Box>
        </Dialog>
    )
}

export default EditUser