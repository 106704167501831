import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import TopBar from './components/TopBar'
import OneClickBooking from './components/OneClickBooking'
import { GlobalContext } from '../../context/GlobalContext'

const Reservation = ({ data }) => {
    const [dialog, setDialog] = React.useState(null);
    const { hitAxios } = React.useContext(GlobalContext)
    const [page, setPage] = React.useState(null)

    const [userDash, setUserDash] = React.useState({})

    const scrollToTop = () => {
        // Find the element you want to scroll
        const element = document.getElementById('shit');

        // Check if the element exists and is scrollable
        if (element && element.scrollTop > 0) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling animation
            });
        }
    };

    async function getDash() {
        const res = await hitAxios({
            path: "/api/user/get_reservation_top_values",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setUserDash(res.data)
        }
    }

    React.useEffect(() => {
        getDash()
        scrollToTop()
    }, [])

    React.useEffect(() => {
        // Function to parse URL query parameters
        function parseUrlParams() {
            // Get the search query string from the URL
            const queryString = window.location.search;

            // Parse the query string using URLSearchParams
            const queryParams = new URLSearchParams(queryString);

            // Get the value of 'user' parameter from the query string
            const userParam = queryParams.get('dialog');
            setDialog(userParam);

            const pageParam = queryParams.get('page');
            setPage(pageParam);
        }

        // Call the parseUrlParams function when the component mounts
        parseUrlParams();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to run the effect only once on component mount


    return (
        <div >
            <Box p={2}>
                <Typography align='center' fontWeight={'bold'} >
                    {data.hotelReser}
                </Typography>
            </Box>

            <Box pr={2} pl={2} >
                <TopBar
                    userDash={userDash}
                    data={data}
                />
            </Box>

            <Box pr={2} pl={2} pt={2} >
                {page && <OneClickBooking
                    scrollToTop={scrollToTop}
                    getDash={getDash}
                    userDash={userDash}
                    dialog={dialog}
                    data={data}
                />}
            </Box>
        </div>
    )
}

export default Reservation