import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

const FeaturedHotels = ({ data, hotels, state, setState }) => {

    return (
        <div>
            <Typography sx={{ pt: 1 }} align='center' fontWeight={'bold'} >
                {data.featuredHotels}
            </Typography>
            <Box p={2} >
                <Grid container spacing={2}>
                    {hotels?.map((i, key) => {
                        return (
                            <Grid key={key} xs={4} sm={4} lg={4} item>
                                <CardMedia
                                    onClick={() => setState({ ...state, dialog: true, dialogData: i })}
                                    src={`/media/${i?.image}`}
                                    component={'img'}
                                    sx={{ height: "100%", width: "100%", borderRadius: 2, cursor: 'pointer' }}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </div>
    )
}

export default FeaturedHotels