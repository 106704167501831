import { Close, Help, Info } from '@mui/icons-material'
import { Avatar, Box, Button, CardMedia, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import RuleComp from './RuleComp'

const CheckLevel = ({ data, state, setState, user }) => {
    return (
        <Dialog
            fullScreen
            open={state.levelDialog}
            onClose={() => setState({ ...state, levelDialog: false })}
        >
            <Container maxWidth="sm">
                <Box mb={2} p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, levelDialog: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.levelDetails}
                        </Typography>
                    </Stack>
                </Box>

                <Stack p={2} direction={'column'} spacing={2}>
                    <Box bgcolor={'action.hover'} borderRadius={2} p={2}>
                        <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                            <Stack direction={'column'}>
                                <Typography variant='h6'>
                                    {data.yourCurrentLevel}
                                </Typography>

                                <Typography variant='h3'>
                                    {user?.user_type}
                                </Typography>
                            </Stack>
                            <CardMedia
                                sx={{
                                    height: 150,
                                    width: 150
                                }}
                                component={'img'}
                                src='/assets/1665412350380.png'
                            />
                        </Stack>

                        <CardMedia
                            component={'img'}
                            src='/assets/help.png'
                            sx={{
                                height: 35,
                                width: 35,
                                cursor: "pointer"
                            }}
                            onClick={() => setState({ ...state, ruleDialog: true })}
                        />

                    </Box>

                    <Typography fontWeight={600} >
                        {data.intro}
                    </Typography>

                    <RuleComp
                        data={data}
                        state={state}
                        setState={setState}
                    />


                    <Typography variant='body2'>
                        {data.introDes}
                    </Typography>
                </Stack>

            </Container>
        </Dialog>
    )
}

export default CheckLevel