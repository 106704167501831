import React from 'react'
import { Stack, Typography, IconButton, Button, CardMedia, TextField } from '@mui/material'
import { AddPhotoAlternate, CheckCircle } from '@mui/icons-material'
import { GlobalContext } from '../../../context/GlobalContext'

const Settings = () => {
    const [set, setSet] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        file: "",
        name: "adad"
    })

    async function addLogo() {
        const fd = new FormData()
        fd.append('file', state.file)
        fd.append('name', state.name)
        fd.append('telegram', set.telegram)
        fd.append('telegram_two', set.telegram_two)
        fd.append('whatsapp', set.whatsapp)
        fd.append('whatsapp_two', set.whatsapp_two)
        const res = await hitAxios({
            path: "/api/admin/update_logo",
            post: true,
            admin: true,
            obj: fd
        })
        if (res.data.success) {
            getSett()
        }
    }

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }

    React.useEffect(() => {
        getSett()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Typography>
                    Add your logo
                </Typography>

                {set.logo &&
                    <CardMedia
                        sx={{ height: 100, width: 100 }}
                        component={'img'}
                        src={`/media/${set?.logo}`}
                    />}

                <Stack direction={'row'}>
                    <IconButton component='label'>
                        <input
                            type='file'
                            hidden
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => setState({ ...state, file: e.target.files[0] })}
                        />
                        {state.file ?
                            <CheckCircle sx={{ color: 'green' }} /> :
                            <AddPhotoAlternate />}
                    </IconButton>

                </Stack>


                <TextField
                    size='small'
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={set.telegram}
                    label="Telegram 1"
                    onChange={(e) => setSet({ ...set, telegram: e.target.value })}
                />

                <TextField
                    size='small'
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={set.telegram_two}
                    label="Telegram 2"
                    onChange={(e) => setSet({ ...set, telegram_two: e.target.value })}
                />

                <TextField
                    size='small'
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={set.whatsapp}
                    label="WhatsApp 1"
                    onChange={(e) => setSet({ ...set, whatsapp: e.target.value })}
                />

                <TextField
                    size='small'
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={set.whatsapp_two}
                    label="WhatsApp 2"
                    onChange={(e) => setSet({ ...set, whatsapp_two: e.target.value })}
                />


                <Button variant='contained' onClick={addLogo}>
                    Update
                </Button>
            </Stack>
        </div>
    )
}

export default Settings