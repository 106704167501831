import { Box, Button, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'

const LoginChange = ({ data, state, setState, user }) => {
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [da, setDa] = React.useState({})

    async function changePw() {
        const res = await hitAxios({
            path: "/api/user/change_pass",
            post: true,
            admin: false,
            obj: da
        })
        if (res.data.success) {
            localStorage.removeItem('token')
            window.location.reload()
        }
    }

    return (
        <Dialog
            fullScreen
            open={state.loginChange}
            onClose={() => setState({ ...state, loginChange: false })}
        >
            <Container maxWidth="sm">
                <Box mb={2} p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, loginChange: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.changePass}
                        </Typography>
                    </Stack>
                </Box>

                <Stack direction={'column'} spacing={2}>

                    <TextFieldNew
                        onChange={(e) => setDa({ ...da, oldPass: e.target.value })}
                        label={data.oldPw}
                        fullWidth
                    />

                    <TextFieldNew
                        onChange={(e) => setDa({ ...da, newPass: e.target.value })}
                        label={data.newPas}
                        fullWidth
                    />

                    <TextFieldNew
                        onChange={(e) => setDa({ ...da, confirmPass: e.target.value })}
                        label={data.confirmNewPass}
                        fullWidth
                    />

                    <LoadingButton
                        onClick={changePw}
                        loading={CON.data.loading}
                        sx={{ borderRadius: 2, boxShadow: 0 }}
                        variant='contained' >
                        {data.submit}
                    </LoadingButton>

                </Stack>

            </Container>
        </Dialog>
    )
}

export default LoginChange