import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import moment from 'moment';
import { Delete } from '@mui/icons-material';

const AnList = ({ data, an, hitAxios, getAnnoun }) => {

    async function delOne(id) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_an",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getAnnoun()
            }
        }
    }

    return (
        <div>
            <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                {an ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...an].reverse()}
                            columns={[
                                {
                                    headerName: "Title", field: 'title', flex: 1
                                },
                                {
                                    headerName: "Show to", field: 'show_to', flex: 1, renderCell: row => {
                                        return (
                                            row.row?.show_to === "ALL" ? row.row?.show_to : JSON.parse(row.row?.user_json)?.username
                                        )
                                    }
                                },
                                {
                                    headerName: "Description", field: 'description', flex: 1
                                },
                                {
                                    headerName: "CreatedAt", field: 'createdAt', flex: 1, renderCell: row => {
                                        return (
                                            moment(row.row.createdAt).format("DD MM YY | hh:mmA")
                                        )
                                    }
                                },
                                {
                                    headerName: "Delete", field: 'del', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton onClick={() => delOne(row.row.id)} color='error' >
                                                <Delete />
                                            </IconButton>
                                        )
                                    }
                                }
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </div>
    )
}

export default AnList