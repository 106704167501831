import { Close, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'

const HelpComp = ({ user, state, setState, data }) => {
    return (
        <Dialog
            fullScreen
            open={state.helpDialog}
            onClose={() => setState({ ...state, helpDialog: false })}
        >
            <Container maxWidth="sm">
                <Box mb={2} p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, helpDialog: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.help}
                        </Typography>
                    </Stack>
                </Box>

                <Stack direction={'column'} spacing={2}>
                    <Accordion sx={{ bgcolor: 'action.hover' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                {data.help1}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                                {data.help11}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ bgcolor: 'action.hover' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                {data.help2}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                                {data.help22}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ bgcolor: 'action.hover' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                {data.help3}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                                {data.help33}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ bgcolor: 'action.hover' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                {data.help4}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                                {data.help44}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ bgcolor: 'action.hover' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                {data.help5}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', lineHeight: '1' }}>
                                {data.help55}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </Container>
        </Dialog >
    )
}

export default HelpComp