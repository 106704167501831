import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton, Switch } from '@mui/material';
import moment from 'moment'
import { AccountBalanceWallet, RestartAlt, Visibility } from '@mui/icons-material';
import EditUser from './EditUser';
import AddBalance from './AddBalance';


const UserList = ({ data, hitAxios, userData, getUsers, userLeverl }) => {
    const [state, setState] = React.useState({
        dialog: false,
        dialogData: {},
        addBalanceDialog: false
    })

    async function resetUser(uid) {
        if (window.confirm("Are you sure to reset the user ?")) {
            const res = await hitAxios({
                path: "/api/admin/reset_user",
                post: true,
                admin: true,
                obj: {
                    uid
                }
            })
            if (res.data.success) {
                getUsers()
            }
        }
    }

    async function updateUserActve(uid, active) {
        const res = await hitAxios({
            path: "/api/admin/change_user_activeness",
            post: true,
            admin: true,
            obj: {
                uid, active
            }
        })
        if (res.data.success) {
            getUsers()
        }
    }

    return (
        <div>
            <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                {userData ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...userData].reverse()}
                            columns={[
                                {
                                    headerName: data.username, field: 'username', flex: 1
                                }, {
                                    headerName: data.email, field: 'email', flex: 1
                                }, {
                                    headerName: data.phone, field: 'phone', flex: 1
                                }, {
                                    headerName: data.referCode, field: 'refer_code', flex: 1
                                }, {
                                    headerName: data.myRefCode, field: 'my_refer_code', flex: 1
                                }, {
                                    headerName: data.userType, field: 'user_type', flex: 1
                                }, {
                                    headerName: data.balance, field: 'balance', flex: 1
                                }, {
                                    headerName: data.undone, field: 'undone', flex: 1
                                }, {
                                    headerName: "Connected", field: 'connected_user', flex: 1, renderCell: row => {
                                        return (
                                            row.row.connected_user ?
                                                JSON.parse(row.row.connected_user)?.username :
                                                "NA"
                                        )
                                    }
                                }, {
                                    headerName: "View", field: 'edit', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton onClick={() => {
                                                setState({ ...state, dialog: true, dialogData: row.row })
                                            }}>
                                                <Visibility />
                                            </IconButton>
                                        )
                                    }
                                }, {
                                    headerName: "Topup", field: 'toptup', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton onClick={() => {
                                                setState({ ...state, addBalanceDialog: true, dialogData: row.row })
                                            }}>
                                                <AccountBalanceWallet />
                                            </IconButton>
                                        )
                                    }
                                }, {
                                    headerName: "Reset User", field: 'reset', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton color='warning' onClick={() => {
                                                resetUser(row.row.uid)
                                            }}>
                                                <RestartAlt />
                                            </IconButton>
                                        )
                                    }
                                }, {
                                    headerName: "Is active?", field: 'is_active', flex: 1, renderCell: row => {
                                        return (
                                            <Switch
                                                onChange={(e) => updateUserActve(row.row.uid, e.target.checked)}
                                                checked={row.row.is_active} />
                                        )
                                    }
                                }, {
                                    headerName: data.createdAt, field: 'createdAt', flex: 1, renderCell: row => {
                                        return (
                                            moment(row.row.createdAt).format("DD MMM YY")
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>

            <EditUser
                userLeverl={userLeverl}
                getUsers={getUsers}
                setState={setState}
                state={state}
                hitAxios={hitAxios}
                data={data}
                userData={userData}
            />

            <AddBalance
                getUsers={getUsers}
                setState={setState}
                state={state}
                hitAxios={hitAxios}
                data={data}
                userData={userData}
            />
        </div>
    )
}

export default UserList