import React from 'react'
import Deposite from './Deposite'
import Widthdraw from './Widthdraw'
import CheckLevel from './CheckLevel'
import { GlobalContext } from '../../../context/GlobalContext'
import AccountDetails from './AccountDetails'
import LoginChange from './LoginChange'
import WithdrawChange from './WithdrawChange'
import HelpComp from './HelpComp'

const MainComp = ({ data, state, setState }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [user, setUser] = React.useState({})

    async function getUser() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }


    React.useEffect(() => {
        getUser()
    }, [])

    return (
        <div>
            <Deposite
                data={data}
                state={state}
                setState={setState}
            />
            <Widthdraw
                data={data}
                state={state}
                setState={setState}
            />
            <CheckLevel
                user={user}
                data={data}
                state={state}
                setState={setState}
            />
            <AccountDetails
                user={user}
                data={data}
                state={state}
                setState={setState}
            />
            <LoginChange
                user={user}
                data={data}
                state={state}
                setState={setState}
            />
            <WithdrawChange
                user={user}
                data={data}
                state={state}
                setState={setState}
            />
            <HelpComp
                user={user}
                data={data}
                state={state}
                setState={setState}
            />
        </div>
    )
}

export default MainComp