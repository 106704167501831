import { Box, Button, Container, CardMedia, IconButton, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../context/TranslateContext'
import TextFieldNew from '../components/TextFieldNew'
import { SupportAgent, Visibility } from '@mui/icons-material'
import { GlobalContext } from '../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SupportPage from '../SupportPage'

const UserLogin = () => {
    const [set, setSet] = React.useState({})
    const history = useHistory()
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        type: "LOGIN"
    })

    async function login() {
        const resp = await hitAxios({
            path: "/api/user/login_user",
            post: true,
            obj: state,
            admin: false
        })

        if (resp.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", resp.data.token)
            history.push('/user')
            return
        }
    }

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }

    async function hitSignup() {
        const res = await hitAxios({
            path: "/api/user/user_signup",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, type: "LOGIN" })
        }
    }

    React.useEffect(() => {
        getSett()
    }, [])

    return (
        <Box bgcolor={'gray'}>
            <div style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0.23)), url(${process.env.PUBLIC_URL}/assets/hotel3.jpg)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: "100vh",
                overflow: "auto"
            }} >

                <Container maxWidth='sm'>


                    <Stack direction={'column'} spacing={6}>
                        <Stack
                            sx={{ bgcolor: "rgba(0, 0, 0, 0.19)" }}
                            alignItems={'center'} direction={'row'} justifyContent={'space-between'}>

                            <Stack ml={1} alignItems={'center'} direction={'row'} spacing={0}>
                                {set.logo &&
                                    <CardMedia
                                        sx={{ height: 30, width: 30, borderRadius: 1 }}
                                        component={'img'}
                                        src={`/media/${set?.logo}`}
                                    />}


                                <Box p={1}>
                                    <Typography
                                        onClick={() => history.push("/")}
                                        sx={{ cursor: "pointer" }}
                                        color={'white'}>
                                        {data.theBokExp}
                                    </Typography>
                                </Box>

                            </Stack>

                            <SupportPage
                                data={data}
                                set={set}
                            />

                            <IconButton onClick={() => {
                                CON?.setData({ ...CON.data, supportPage: true })
                            }}>
                                <SupportAgent sx={{
                                    color: "white", height: 60,
                                    width: 60
                                }} />
                            </IconButton>
                        </Stack>

                    </Stack>



                    <Box sx={{
                        mt: "35vh",
                        height: "70vh",
                        borderTopRightRadius: 30,
                        borderTopLeftRadius: 30
                    }} bgcolor={'white'} p={3}>


                        <Stack alignItems={'center'}>
                            {set.logo &&
                                <CardMedia
                                    sx={{ height: 100, width: 100 }}
                                    component={'img'}
                                    src={`/media/${set?.logo}`}
                                />}

                        </Stack>

                        {state.type === "LOGIN" &&
                            <Stack mt={1} direction={'column'} spacing={3}>
                                <Typography variant='h5' fontWeight={600} >
                                    {data.signInToCOn}
                                </Typography>

                                <TextFieldNew
                                    value={state.username}
                                    onChange={(e) => setState({ ...state, username: e.target.value })}
                                    label={data.userName}
                                />


                                <TextFieldNew
                                    type='password'
                                    value={state.password}
                                    onChange={(e) => setState({ ...state, password: e.target.value })}
                                    label={data.password}
                                />

                                <Button
                                    onClick={login}
                                    variant='contained' sx={{ borderRadius: 2, boxShadow: 0 }} >
                                    {data.login}
                                </Button>

                                <Stack
                                    direction={'row'} justifyContent={'flex-end'}>
                                    <Typography
                                        onClick={() => {
                                            CON?.setData({ ...CON.data, supportPage: true })
                                        }}
                                        sx={{
                                            color: (t) => t.palette.primary.main,
                                            cursor: 'pointer'
                                        }} fontWeight={600}>
                                        {data.forgotPass}
                                    </Typography>
                                </Stack>

                                <Box display="flex" alignItems="center">
                                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginRight={2} />
                                    <Typography variant="body2" fontWeight={500} color={'gray'} >{data.signuphere}</Typography>
                                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginLeft={2} />
                                </Box>

                                <Typography
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setState({ ...state, type: "SIGNUP" })
                                    }}
                                    align='center' >
                                    {data.clickToSignup}
                                </Typography>
                            </Stack>}

                        {state.type === "SIGNUP" &&
                            <Stack mt={1} direction={'column'} spacing={3}>
                                <Typography variant='h5' fontWeight={600} >
                                    {data.createYourAccount}
                                </Typography>

                                <TextFieldNew
                                    value={state.username}
                                    onChange={(e) => setState({ ...state, username: e.target.value })}
                                    label={data.userName}
                                />

                                <TextFieldNew
                                    value={state.email}
                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                    label={data.email}
                                />
                                <TextFieldNew
                                    type='password'
                                    value={state.password}
                                    onChange={(e) => setState({ ...state, password: e.target.value })}
                                    label={data.password}
                                />

                                <TextFieldNew
                                    value={state.phone}
                                    onChange={(e) => setState({ ...state, phone: e.target.value })}
                                    type='number'
                                    label={data.phoneNumber}
                                />

                                <TextFieldNew
                                    value={state.refer_code}
                                    onChange={(e) => setState({ ...state, refer_code: e.target.value })}
                                    label={data.referCode}
                                />

                                <TextFieldNew
                                    value={state.withdraw_password}
                                    onChange={(e) => setState({ ...state, withdraw_password: e.target.value })}
                                    label={data.withdraw_password}
                                />

                                <Button
                                    onClick={hitSignup}
                                    variant='contained' sx={{ borderRadius: 2, boxShadow: 0 }} >
                                    {data.signuphere}
                                </Button>

                                <Box display="flex" alignItems="center">
                                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginRight={2} />
                                    <Typography variant="body2" fontWeight={500} color={'gray'} >{data.login}</Typography>
                                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginLeft={2} />
                                </Box>

                                <Typography
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setState({ ...state, type: "LOGIN" })
                                    }}
                                    align='center' >
                                    {data.clickHeretoLogin}
                                </Typography>
                            </Stack>}
                    </Box>
                </Container>
            </div >
        </Box>
    )
}

export default UserLogin