import { Box, CardMedia, Stack, Typography } from '@mui/material'
import React from 'react'

const TopImage = ({ data }) => {
    return (
        <div>
            <Box position={'relative'}>
                <CardMedia
                    src='/assets/hotel2.jpg'
                    component={'img'}
                    height={300}
                />
                <Box bgcolor={'rgba(0, 0, 0, 0.15)'} p={1} position={'absolute'} bottom={0}>
                    <Stack direction={'column'}>
                        <Typography color={'white'} fontWeight={'bold'}>
                            {data.scheduleRecord}
                        </Typography>
                        <Typography color={'white'} variant='body2'>
                            {data.viewYourBooking}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </div>
    )
}

export default TopImage