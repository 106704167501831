import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../context/TranslateContext'
import { GlobalContext } from '../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AdminLogin = () => {
    const [state, setState] = React.useState({})
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const history = useHistory()

    async function hitLogin() {
        const res = await hitAxios({
            path: "/api/admin/login",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_admin", res.data.token)
            history.push('/admin')
            return
        }
    }

    return (
        <Grid minHeight={'100vh'} alignItems={'center'} justifyContent={'center'} container >
            <Grid xs={12} sm={6} lg={4} item>
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={2}>
                        <Typography
                            variant='h3'>
                            {data.helloAdmin}
                        </Typography>
                        <TextField
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            size='small'
                            label="Email"
                        />
                        <TextField
                            value={state.password}
                            onChange={(e) => setState({ ...state, password: e.target.value })}
                            size='small'
                            label="Password"
                        />
                        <Button
                            onClick={hitLogin}
                            variant='contained' >
                            {data.login}
                        </Button>
                    </Stack>
                </Container>
            </Grid>
        </Grid>

    )
}

export default AdminLogin