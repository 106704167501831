import { Close, Star, StarOutline } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Dialog, Divider, IconButton, Stack, Typography, CircularProgress } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../../context/GlobalContext'
import randomstring from 'randomstring'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const OneClickDialog = ({ data, state, setState, setSimu, hitAxios, getDash, scrollToTop }) => {
    const CON = React.useContext(GlobalContext)
    const history = useHistory()
    const [msg, setMsg] = React.useState(0)
    const [load, setLoad] = React.useState(false)
    const [states, setStates] = React.useState({
        rating: [],
    })

    async function addReservation() {
        setLoad(true)
        setMsg(1)
        await new Promise(resolve => setTimeout(resolve, 2500));
        setMsg(2)
        await new Promise(resolve => setTimeout(resolve, 6500));
        const res = await hitAxios({
            path: `/api/user/add_task_reservation${state?.force ? '?force=true' : ""}`,
            post: true,
            admin: false,
            obj: {
                order_id: state?.hotelData?.order_id,
                ratings: states?.rating
            }
        })
        scrollToTop()
        history.push('/user?page=reservation')
        setLoad(false)
        setSimu("")
        setMsg(0)
        if (res.data.success) {
            setState({ ...state, dialog: false })
            setStates({ ...states, rating: [] })
            getDash()
            scrollToTop()
        }
    }


    return (
        <Dialog
            open={state.dialog}
            fullScreen >

            <Dialog open={msg === 1 || msg === 2}>
                <Stack alignItems={'center'} direction={'column'} spacing={2} p={2}>
                    <CircularProgress />
                    <Typography align='center'>
                        {msg === 1 && data.msg1}
                        {msg === 2 && data.msg2}
                    </Typography>
                </Stack>
            </Dialog>

            <Container maxWidth='sm'>
                <Box
                    p={2}
                    sx={{ bgcolor: (t) => t.palette.primary.main }}>
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        <IconButton onClick={() => {
                            setState({ ...state, dialog: false })
                            setSimu("")
                            history.push('/user?page=reservation')
                            scrollToTop()
                            getDash()
                        }} >
                            <Close sx={{ color: "white" }} />
                        </IconButton>
                        <Typography color={'white'}>
                            {data.close}
                        </Typography>
                    </Stack>
                </Box>

                <Box pt={2}>

                    <Box bgcolor={'action.hover'} p={2} borderRadius={2}>
                        <Box>
                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                                <CardMedia
                                    sx={{ height: "100%", width: 60 }}
                                    component={'img'}
                                    src={`/media/${state?.hotelData?.image}`}
                                />
                                <Typography>
                                    {state?.hotelData?.name}
                                </Typography>
                            </Stack>
                        </Box>

                        <Box pt={2} pb={2} >
                            <Divider />
                        </Box>

                        <Box>
                            <Stack direction={'column'} spacing={1}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='caption' >
                                        {data.orderNum}
                                    </Typography>
                                    <Typography variant='caption' >
                                        {state?.hotelData?.order_id}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='caption' >
                                        {data.time}
                                    </Typography>
                                    <Typography variant='caption' >
                                        {moment(state?.hotelData?.createdAt)?.format("DD MMM YY hh:mmA")}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='caption' >
                                        {data.bookingAmount}
                                    </Typography>
                                    <Typography variant='caption' >
                                        ${state?.hotelData?.amount}
                                    </Typography>
                                </Stack>


                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography color={state?.hotelData?.premium > 0 ? 'red' : 'black'} variant={state?.hotelData?.premium > 0 ? 'h5' : 'caption'} >
                                        {data.rewardRate} {state?.hotelData?.premium > 0 && <a style={{
                                            fontSize: 20,
                                            fontWeight: 'bold'
                                        }}> 10x </a>}
                                    </Typography>
                                    <Typography
                                        fontWeight={state?.hotelData?.is_premium > 0 && 600}
                                        color={state?.hotelData?.is_premium > 0 ? 'red' : 'black'}
                                        variant={state?.hotelData?.is_premium > 0 ? 'h6' : 'caption'} >
                                        {state?.hotelData?.is_premium > 0 ? `10X Extra Commission ` + `$${state?.hotelData?.commission_earned?.replace("-", "")}` : `$${state?.hotelData?.commission_earned}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box pt={2} pb={2} >
                            <Divider />
                        </Box>

                        <Box>
                            <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                <Typography>
                                    {data.elevation}
                                </Typography>

                                <Stack direction={'row'}>
                                    {[1, 2, 3, 4, 5].map((value, index) => (
                                        <IconButton
                                            key={index}
                                            onClick={() => {
                                                setStates({ ...states, rating: Array.from({ length: value }, (_, i) => i + 1) });
                                            }}
                                        >
                                            {states?.rating?.length >= value ? <Star
                                                sx={{
                                                    color: "gold",
                                                    height: 30, width: 30
                                                }} /> : <StarOutline sx={{ height: 30, width: 30 }} />}
                                        </IconButton>
                                    ))}
                                </Stack>
                            </Stack>
                        </Box>

                        <Box pt={2} pb={2} >
                            <Divider />
                        </Box>

                        <LoadingButton
                            loading={load}
                            onClick={addReservation}
                            fullWidth
                            variant='contained' sx={{ borderRadius: 2, boxShadow: 0 }} >
                            {data.confirmReservation}
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    )
}

export default OneClickDialog