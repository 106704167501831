import { Close } from '@mui/icons-material'
import { Box, Button, Chip, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../../components/TextFieldNew'

const AddUser = ({ data, hitAxios, userData, getUsers, userLeverl }) => {
    const [state, setState] = React.useState({
        dialog: false,
        user_type: "NORMAL"
    })

    async function addUser() {
        const res = await hitAxios({
            path: "/api/admin/add_manager",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, dialog: false })
        }
    }

    return (
        <div>
            <Button
                onClick={() => setState({ ...state, dialog: true })}
                variant='contained'>
                Add User
            </Button>

            <Dialog
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }}
                open={state.dialog}
                onClose={() => setState({ ...state, dialog: false })} >
                <Box p={2} >
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <IconButton onClick={() => setState({ ...state, dialog: false })} >
                                <Close />
                            </IconButton>
                        </Stack>

                        <Stack direction={'column'} spacing={2}>
                            <TextFieldNew
                                value={state.username}
                                onChange={(e) => setState({ ...state, username: e.target.value })}
                                label="Username"
                            />

                            <TextFieldNew
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                label="Email"
                            />

                            <TextFieldNew
                                value={state.phone}
                                onChange={(e) => setState({ ...state, phone: e.target.value })}
                                label="Phone"
                            />

                            <TextFieldNew
                                value={state.password}
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                                label="Password"
                            />

                            <TextFieldNew
                                value={state.withdraw_password}
                                onChange={(e) => setState({ ...state, withdraw_password: e.target.value })}
                                label="Withdraw password"
                            />

                            <TextFieldNew
                                value={state.refer_code}
                                onChange={(e) => setState({ ...state, refer_code: e.target.value })}
                                label="Reffer code"
                            />

                            <Stack sx={{ overflowX: 'scroll' }} direction={'row'} spacing={2}>
                                <Chip
                                    onClick={() => setState({ ...state, user_type: "NORMAL" })}
                                    variant={state.user_type === "NORMAL" ? 'filled' : 'outlined'}
                                    label="NORMAL" />

                                <Chip
                                    onClick={() => setState({ ...state, user_type: "VIP1" })}
                                    variant={state.user_type === "VIP1" ? 'filled' : 'outlined'}
                                    label="VIP1" />

                                <Chip
                                    onClick={() => setState({ ...state, user_type: "VIP2" })}
                                    variant={state.user_type === "VIP2" ? 'filled' : 'outlined'}
                                    label="VIP2" />

                                <Chip
                                    onClick={() => setState({ ...state, user_type: "VIP3" })}
                                    variant={state.user_type === "VIP3" ? 'filled' : 'outlined'}
                                    label="VIP3" />

                                <Chip
                                    onClick={() => setState({ ...state, user_type: "VIP4" })}
                                    variant={state.user_type === "VIP4" ? 'filled' : 'outlined'}
                                    label="VIP4" />
                            </Stack>

                            <FormControl
                                size='small'
                                sx={{ borderRadius: 10 }}
                                fullWidth>
                                <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                                <Select
                                    size='small'
                                    sx={{ borderRadius: 2.5 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select User"
                                >
                                    {userData.map((i, key) => {
                                        return (
                                            <MenuItem
                                                onClick={() => setState({ ...state, connected_user: i, connected_user_uid: i?.uid })}
                                                key={key} value={10}>
                                                {i?.username}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <Button
                                variant='contained'
                                onClick={addUser}>
                                Add User
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
        </div >
    )
}

export default AddUser