import { Close } from '@mui/icons-material'
import { Box, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'

const AccountDetails = ({ data, state, setState, user }) => {
    return (
        <Dialog
            fullScreen
            open={state.accountDialog}
            onClose={() => setState({ ...state, accountDialog: false })}
        >
            <Container maxWidth="sm">
                <Box mb={2} p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, accountDialog: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.levelDetails}
                        </Typography>
                    </Stack>
                </Box>

                <Stack direction={'column'} spacing={2}>
                    <Box bgcolor={'action.hover'} p={2} borderRadius={2}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600}>
                                {data.balance}
                            </Typography>

                            <Typography fontWeight={600} color={'orange'}>
                                ${parseFloat(user?.balance)?.toFixed(2)}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Container>

        </Dialog>
    )
}

export default AccountDetails