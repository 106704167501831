async function getMe(hitAxios) {
    const res = await hitAxios({
        path: "/api/user/get_me",
        post: false,
        admin: false,
        token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    });
    return res.data;
}

export { getMe }
