import { Box, Grid, Stack, Typography, IconButton } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { SupportAgent } from '@mui/icons-material'
import SupportPage from '../../../SupportPage'

const TopBar = ({ data, userDash }) => {
    const [set, setSet] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }

    React.useEffect(() => {
        getSett()
    }, [])

    return (
        <div>
            <Grid container spacing={2} >
                <Grid item xs={4} sm={4} lg={4}>
                    <Box borderRadius={2} bgcolor={'#223342'} p={2}>
                        <Stack spacing={2} direction={'column'}>
                            <Typography variant='caption' align='center' color={'white'} >
                                {data.balance}
                            </Typography>

                            <Typography variant='caption' align='center' color={'white'} >
                                {userDash?.userBal}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={4} sm={4} lg={4}>
                    <Box borderRadius={2} bgcolor={'#223342'} p={2}>
                        <Stack spacing={2} direction={'column'}>
                            <Typography variant='caption' align='center' color={'white'} >
                                {data.numOfTask}
                            </Typography>

                            <Typography variant='caption' align='center' color={'white'} >
                                {userDash?.numOfTask}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={4} sm={4} lg={4}>
                    <Box borderRadius={2} bgcolor={'#223342'} p={2}>
                        <Stack direction={'column'}>
                            <Typography variant='caption' align='center' color={'white'} >
                                {data.support}
                            </Typography>

                            <SupportPage
                                data={data}
                                set={set}
                            />

                            <IconButton onClick={() => {
                                CON?.setData({ ...CON.data, supportPage: true })
                            }} size='small'>
                                <SupportAgent
                                    sx={{ color: "white" }}
                                />
                            </IconButton>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={2}>
                <Grid container spacing={2} >
                    <Grid item xs={4} sm={4} lg={4}>
                        <Box borderRadius={2} bgcolor={'#223342'} p={2}>
                            <Stack direction={'column'}>
                                <Typography variant='caption' align='center' color={'white'} >
                                    {data.unDOne}
                                </Typography>

                                <Typography variant='caption' align='center' color={'white'} >
                                    {userDash?.undoneBal}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} lg={4}>
                        <Box borderRadius={2} bgcolor={'#223342'} p={2}>
                            <Stack direction={'column'}>
                                <Typography variant='caption' align='center' color={'white'} >
                                    {data.commission}
                                </Typography>

                                <Typography variant='caption' align='center' color={'white'} >
                                    {userDash?.commissionBal}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} lg={4}>
                        <Box height={40} borderRadius={2} bgcolor={'#223342'} p={2}>
                            <Stack direction={'column'}>
                                <Typography variant='caption' align='center' color={'white'} >
                                    -
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default TopBar