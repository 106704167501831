import { Box, Button, Dialog, Stack } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../../components/TextFieldNew'

const AddBalance = ({ hitAxios, state, setState, data, userData, getUsers }) => {
    const [bal, setBal] = React.useState({})

    async function addBal() {
        const res = await hitAxios({
            path: "/api/admin/add_balance",
            post: true,
            admin: true,
            obj: { ...bal, uid: state.dialogData?.uid }
        })
        if (res.data.success) {
            getUsers()
            setState({ ...state, addBalanceDialog: false })
        }
    }

    return (
        <Dialog open={state.addBalanceDialog} onClose={() => setState({ ...state, addBalanceDialog: false })} fullWidth>
            <Box p={2} >
                <Stack direction={'column'} spacing={2}>
                    <TextFieldNew
                        onChange={(e) => setBal({ ...bal, amount: e.target.value })}
                        type='number'
                        label="Balance"
                    />
                    <TextFieldNew
                        onChange={(e) => setBal({ ...bal, comment: e.target.value })}
                        label="Comment"
                    />
                    <Button onClick={addBal}>
                        Add
                    </Button>
                </Stack>
            </Box>
        </Dialog>
    )
}

export default AddBalance