import React from 'react'
import { GlobalContext } from '../../../../../context/GlobalContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, CardMedia, Chip, Dialog, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { PublishedWithChanges } from '@mui/icons-material';
import TextFieldNew from '../../../../../components/TextFieldNew';
import { LoadingButton } from '@mui/lab'

const ViewTaskReport = ({ data, dialogData, hitAxios }) => {
    const CON = React.useContext(GlobalContext)
    const [hotels, setHotels] = React.useState([])
    const [task, setTask] = React.useState([])
    const [state, setState] = React.useState({
        showType: "PENDING",
        dialog: false,
        dailogHote: {},
        replaceHotel: {}
    })

    async function replaceHotel() {
        const res = await hitAxios({
            path: "/api/admin/replace_task",
            post: true,
            admin: true,
            obj: {
                newHotel: state.replaceHotel,
                oldHotelId: state.dailogHote
            }
        })
        if (res.data.success) {
            getTaskCompleted()
            getTask()
        }
    }

    async function getHotelsAdded() {
        const res = await hitAxios({
            path: "/api/admin/get_hotel_added",
            post: true,
            admin: true,
            obj: { section: dialogData?.user_type }
        })
        if (res.data.success) {
            setHotels(res.data.data)
        }
    }

    async function getTaskCompleted() {
        setState({ ...state, showType: "COMPLETED" })
        const res = await hitAxios({
            path: "/api/admin/get_user_task_completed",
            post: true,
            admin: true,
            obj: { uid: dialogData?.uid }
        })
        if (res.data.success) {
            setTask(res.data.data)
        }
    }

    async function getTask() {
        setState({ ...state, showType: "PENDING" })
        const res = await hitAxios({
            path: "/api/admin/get_user_task_pending",
            post: true,
            admin: true,
            obj: { uid: dialogData?.uid }
        })
        if (res.data.success) {
            setTask(res.data.data)
        }
    }

    React.useEffect(() => {
        getTask()
        getHotelsAdded()
    }, [])


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Stack alignItems={'flex-end'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            {/* {selected.length > 0 && <Typography color={'gray'} fontWeight={600} >({selected.length})</Typography>}
                            <LoadingButton
                                loading={CON.loading}
                                onClick={addTask}
                                disabled={selected.length < 1 ? true : false}>
                                Add as task
                            </LoadingButton> */}
                            <GridToolbarExport />
                        </Stack>
                    </Stack>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            {CON.loading &&
                <Box mb={2}>
                    <LinearProgress />
                </Box>}
            <Stack mb={2} direction={'row'} spacing={2} alignItems={'center'}>
                <Chip
                    onClick={getTask}
                    label="PENDING"
                    variant={state.showType === "PENDING" ? 'filled' : 'outlined'}
                />
                <Chip
                    onClick={getTaskCompleted}
                    label="COMPLETED"
                    variant={state.showType === "COMPLETED" ? 'filled' : 'outlined'}
                />
            </Stack>


            <Dialog fullWidth
                open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography>
                            Select an hotel to add
                        </Typography>
                        <Autocomplete
                            onChange={(event, selectedHotel) => setState({ ...state, replaceHotel: selectedHotel })}
                            fullWidth
                            // disablePortal
                            id="combo-box-demo"
                            options={hotels.map((hotel) => ({
                                ...hotel,
                                label: `${hotel.premium > 0 ? "👑 " : ""}${hotel.name}`,
                                key: hotel.hotel_id // Assuming 'hotel_id' is a unique identifier
                            }))}
                            renderInput={(params) => (
                                <Stack spacing={1} direction={'row'}>
                                    <TextField fullWidth size='small' {...params} label="Hotel" />
                                </Stack>
                            )}
                        />


                        <LoadingButton
                            loading={CON.loading}
                            onClick={replaceHotel}
                            disabled={state.replaceHotel?.name ? false : true}
                            sx={{ boxShadow: 0, borderRadius: 2 }}
                            variant='contained' >
                            Repalce with this hotel
                        </LoadingButton>
                    </Stack>
                </Box>
            </Dialog>

            <Box borderRadius={4} bgcolor={'action.hover'} >
                {task ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.hotel_id}
                            rows={task}
                            columns={[
                                {
                                    headerName: "Image", field: 'image', flex: 1, renderCell: row => {
                                        return (
                                            <CardMedia
                                                sx={{ height: "100%", width: 50 }}
                                                component={'img'}
                                                src={`/media/${row.row.image}`}
                                            />
                                        )
                                    }
                                },
                                { headerName: "Name", field: 'name', flex: 1 },
                                { headerName: "Amount", field: 'amount', flex: 1 },
                                { headerName: "Order ID", field: 'order_id', flex: 1 },
                                { headerName: "Hotel ID", field: 'hotel_id', flex: 1 },
                                { headerName: "Premium", field: 'is_premium', flex: 1 },
                                { headerName: "Satus", field: 'status', flex: 1 },
                                { headerName: "Commission", field: 'commission_earned', flex: 1 },
                                { headerName: "Show key", field: 'show_key', flex: 1 },
                                {
                                    headerName: "Ratings", field: 'ratings', flex: 1, renderCell: row => {
                                        return (
                                            JSON.parse(row.row.ratings).length + "/5"
                                        )
                                    }
                                },
                                {
                                    headerName: "Replace Task", field: 'replace', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton onClick={() => setState({ ...state, dialog: true, dailogHote: row.row })}>
                                                <PublishedWithChanges />
                                            </IconButton>
                                        )
                                    }
                                },
                            ]}
                            // onRowSelectionModelChange={handleSelectionChange}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={true}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }


            </Box>
        </div>
    )
}

export default ViewTaskReport