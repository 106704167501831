import { Call, Close } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from './context/GlobalContext'

const SupportPage = ({ data, set }) => {
    const CON = React.useContext(GlobalContext)
    return (
        <Dialog
            fullScreen open={CON?.data?.supportPage}>
            <Container maxWidth='sm'>
                <Box p={2} bgcolor={'action.hover'}>
                    <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                        <IconButton onClick={() => {
                            CON?.setData({ ...CON.data, supportPage: false })
                        }} >
                            <Close />
                        </IconButton>

                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Call />
                            <Typography>
                                {data.conUs}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>

                <Grid
                    alignItems={'center'}
                    justifyContent={'center'}
                    container minHeight={'80vh'}>
                    <Grid item>
                        <Stack alignItems={'center'} direction={'column'} spacing={2}>
                            <CardMedia
                                component={'img'}
                                sx={{
                                    height: 100,
                                    width: 100
                                }}
                                src='/assets/cus.png'
                            />

                            <Stack alignItems={'center'} direction={'column'}>
                                <Typography fontWeight={600} >
                                    {data.conForSupport}
                                </Typography>

                                <Typography color={'gray'} fontWeight={300} >
                                    {data.pleaseChooseOne}
                                </Typography>
                            </Stack>


                            {set?.telegram &&
                                <Button
                                    size='small'
                                    fullWidth
                                    onClick={() => {
                                        window.open(set?.telegram)
                                    }}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'>
                                    {data.tele1}
                                </Button>}

                            {set?.telegram_two &&
                                <Button
                                    size='small'
                                    fullWidth
                                    onClick={() => {
                                        window.open(set?.telegram_two)
                                    }}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'>
                                    {data.tel2}
                                </Button>}

                            {set?.whatsapp &&
                                <Button
                                    size='small'
                                    fullWidth
                                    onClick={() => {
                                        window.open(set?.whatsapp)
                                    }}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'>
                                    {data.whatsapp1}
                                </Button>}

                            {set?.whatsapp_two &&
                                <Button
                                    size='small'
                                    fullWidth
                                    onClick={() => {
                                        window.open(set?.whatsapp_two)
                                    }}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'>
                                    {data.whatsApp2}
                                </Button>}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}

export default SupportPage