import React from 'react'
import TopImage from './components/TopImage'
import { GlobalContext } from '../../context/GlobalContext'
import { Box, Button, CardMedia, Chip, Divider, Stack, Typography } from '@mui/material'
import { Star } from '@mui/icons-material'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Orders = ({ data }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const history = useHistory()
    const [task, setTask] = React.useState([])
    const [showType, setShowType] = React.useState("ALL")

    async function getTask(type) {
        setShowType(type)
        const res = await hitAxios({
            path: "/api/user/get_my_task_all",
            post: true,
            admin: false,
            obj: {
                type: type || "ALL"
            }
        })
        if (res.data.success) {
            setTask(res.data.data)
        }
    }

    React.useEffect(() => {
        getTask("ALL")
    }, [])

    return (
        <div>
            <TopImage
                data={data}
            />

            <Stack p={2} direction={'row'} spacing={2} justifyContent={'space-evenly'}>
                <Chip
                    sx={{ width: "100%" }}
                    label={data.all}
                    variant={showType === "ALL" ? 'filled' : 'outlined'}
                    onClick={() => getTask("ALL")}
                />

                <Chip
                    sx={{ width: "100%" }}
                    label={data.pending}
                    variant={showType === "PENDING" ? 'filled' : 'outlined'}
                    onClick={() => getTask("PENDING")}
                />

                <Chip
                    sx={{ width: "100%" }}
                    label={data.completed}
                    variant={showType === "COMPLETED" ? 'filled' : 'outlined'}
                    onClick={() => getTask("COMPLETED")}
                />
            </Stack>

            <Stack direction={'column'} spacing={2}>
                {[...task]?.reverse().map((i, key) => {
                    return (
                        <Box p={2} borderRadius={2} bgcolor={'action.hover'} key={key}>

                            <Stack direction={'column'} spacing={1}>
                                <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                    <Typography fontWeight={600} variant='caption' >
                                        {i?.name}
                                    </Typography>

                                    <Box
                                        style={{ border: `2px solid ${i?.status === "PENDING" ? 'orange' : 'green'}`, borderWidth: 2 }}
                                        p={1} >

                                        <Typography
                                            variant='caption' >
                                            {i?.status === "PENDING" ? data.pending : data.completed}
                                        </Typography>

                                    </Box>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} spacing={2}>
                                    <CardMedia
                                        component={'img'}
                                        src={`/media/${i?.image}`}
                                        sx={{ height: 100, width: 100, borderRadius: 2 }}
                                    />

                                    <Stack width={'100%'} direction={'column'} spacing={0}>
                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='caption'>
                                                {data?.orderNum}:
                                            </Typography>

                                            <Typography variant='caption'>
                                                {i?.order_id}
                                            </Typography>
                                        </Stack>

                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='caption'>
                                                {data?.schedule}:
                                            </Typography>

                                            <Typography variant='caption'>
                                                {moment(i?.createdAt).format("DD MM YY | hh:mmA")}
                                            </Typography>
                                        </Stack>

                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='caption'>
                                                {data?.bookAmount}:
                                            </Typography>

                                            <Typography variant='caption'>
                                                ${i?.amount}
                                            </Typography>
                                        </Stack>

                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='caption'>
                                                {data?.commission}:
                                            </Typography>

                                            <Typography
                                                fontWeight={i?.is_premium > 0 && 600}
                                                color={i?.is_premium > 0 ? 'red' : 'black'}
                                                variant={i?.is_premium > 0 ? 'h6' : 'caption'} >
                                                {i?.is_premium > 0 ? `10X Extra Commission ` + `$${i?.commission_earned?.replace("-", "")}` : `$${i?.commission_earned}`}
                                            </Typography>

                                            {/* <Typography variant='caption'>
                                                ${i?.is_premium > 0 ? `10x` + i?.commission_earned : i?.commission_earned}
                                            </Typography> */}
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Divider />

                                {i?.status !== "PENDING" && <Stack justifyContent={'center'} direction={'row'} spacing={1}>
                                    <Typography>
                                        {data.evalution}
                                    </Typography>
                                    {i?.ratings &&
                                        new Array(JSON.parse(i?.ratings)?.length).fill().map((i, key) => {
                                            return (
                                                <Star sx={{ color: "gold" }} />
                                            )
                                        })
                                    }
                                </Stack>}

                                {i?.status === "PENDING" && <Button
                                    onClick={() => history.push(`/user?page=reservation&dialog=true`)}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'
                                    size='small'
                                    fullWidth>
                                    {data.completeReservation}
                                </Button>}

                            </Stack>
                        </Box>
                    )
                })}
            </Stack>
        </div>
    )
}

export default Orders