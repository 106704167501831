import { Box, Button, CardActionArea, Chip, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import HotelList from './HotelList'

const AddHotels = ({ hitAxios, data }) => {
    const [hotels, setHotels] = React.useState([])
    const [state, setState] = React.useState({
        premium: false,
        section: "NORMAL"
    })

    async function getHotels() {
        const res = await hitAxios({
            path: "/api/admin/get_hotels",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setHotels(res.data.data)
        }
    }

    async function addHotel() {
        const fd = new FormData()
        fd.append('file', state.file)
        fd.append('name', state.name)
        fd.append('amount', state.amount)
        fd.append('description', state.description)
        fd.append('section', state.section)
        fd.append('premium', state.premium ? 1 : 0)
        const res = await hitAxios({
            path: "/api/admin/add_hotel",
            post: true,
            admin: true,
            obj: fd
        })
        if (res.data.success) {
            getHotels()
            setState({
                ...state,
                file: "",
                name: "",
                description: "",
                amount: "",
                premium: false
            })
        }
    }

    React.useEffect(() => {
        getHotels()
    }, [])

    return (
        <div>
            <Box bgcolor={'white'} p={2} borderRadius={2}>
                <Stack direction={'column'} spacing={2}>
                    <Stack spacing={2} direction={'row'}>
                        <TextFieldNew
                            onChange={(e) => setState({ ...state, name: e.target.value })}
                            fullWidth
                            label={data.hoteName}
                            value={state.name}
                        />

                        <TextFieldNew
                            startIcon={<Typography>$</Typography>}
                            onChange={(e) => setState({ ...state, amount: e.target.value })}
                            fullWidth
                            type='number'
                            label={data.amount}
                            value={state.amount}
                        />
                    </Stack>

                    <TextFieldNew
                        onChange={(e) => setState({ ...state, description: e.target.value })}
                        multiline
                        rows={2}
                        label={data.shortDes}
                        value={state.description}
                    />

                    <CardActionArea
                        component='label'
                        sx={{ borderRadius: 2, bgcolor: "action.hover", p: 2 }} >
                        <input
                            type='file'
                            hidden
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => setState({ ...state, file: e.target.files[0] })}
                        />
                        <Typography align='center' >
                            {state.file ? data.fileSelected : data.selImage}
                        </Typography>
                    </CardActionArea>

                    <Stack direction={'row'} spacing={1}>
                        <Chip
                            label="NORMAL"
                            variant={state.section === "NORMAL" ? 'filled' : 'outlined'}
                            onClick={() => setState({ ...state, section: "NORMAL" })}
                        />
                        <Chip
                            label="VIP1"
                            variant={state.section === "VIP1" ? 'filled' : 'outlined'}
                            onClick={() => setState({ ...state, section: "VIP1" })}
                        />
                        <Chip
                            label="VIP2"
                            variant={state.section === "VIP2" ? 'filled' : 'outlined'}
                            onClick={() => setState({ ...state, section: "VIP2" })}
                        />
                        <Chip
                            label="VIP3"
                            variant={state.section === "VIP3" ? 'filled' : 'outlined'}
                            onClick={() => setState({ ...state, section: "VIP3" })}
                        />
                        <Chip
                            label="VIP4"
                            variant={state.section === "VIP4" ? 'filled' : 'outlined'}
                            onClick={() => setState({ ...state, section: "VIP4" })}
                        />
                    </Stack>


                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <Typography>
                            {data.isPremium}
                        </Typography>
                        <Switch
                            onChange={(e) => setState({ ...state, premium: e.target.checked })}
                            checked={state.premium}
                        />
                    </Stack>

                    <Button
                        onClick={addHotel}
                        variant='contained'
                        sx={{ borderRadius: 2, boxShadow: 0 }}
                    >
                        {data.submit}
                    </Button>
                </Stack>
            </Box>

            <HotelList
                hotels={hotels}
                getHotels={getHotels}
                data={data}
                hitAxios={hitAxios}
            />
        </div>
    )
}

export default AddHotels