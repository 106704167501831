import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton } from '@mui/material'
import React from 'react'

const Deposite = ({ data, state, setState }) => {
    return (
        <Dialog
            fullScreen
            open={state.depositeDialog}
            onClose={() => setState({ ...state, depositeDialog: false })}
        >
            <Box p={2} bgcolor={'action.hover'} >
                <IconButton onClick={() => setState({ ...state, depositeDialog: false })} >
                    <Close />
                </IconButton>
            </Box>

            <Box mt={2}>

            </Box>
        </Dialog>
    )
}

export default Deposite