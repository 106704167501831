import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const Transaction = ({ dialogData, data, hitAxios }) => {
    const [bal, setBal] = React.useState([])

    async function getTrans() {
        const res = await hitAxios({
            path: "/api/admin/get_user_trans",
            post: true,
            admin: true,
            obj: { uid: dialogData?.uid }
        })
        if (res.data.success) {
            setBal(res.data.data)
        }
    }

    React.useEffect(() => {
        getTrans()
    }, [])

    return (
        <div>
            <Box borderRadius={4} bgcolor={'action.hover'} >
                {bal ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...bal].reverse()}
                            columns={[
                                {
                                    headerName: "Username", field: 'user_json', flex: 1, renderCell: row => {
                                        return (
                                            row.row.user_json &&
                                            JSON.parse(row.row.user_json)?.username
                                        )
                                    }
                                },
                                {
                                    headerName: "Comments", field: 'comment', flex: 1
                                },
                                {
                                    headerName: "Amount", field: 'amount', flex: 1
                                }
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={true}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </div>
    )
}

export default Transaction