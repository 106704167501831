import React from 'react'
import { Stack, Typography, IconButton, CardMedia } from '@mui/material'
import { GlobalContext } from '../../../context/GlobalContext'
import { AddCircleOutlineRounded } from '@mui/icons-material'

const DialogImg = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [web, setWeb] = React.useState({})

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    async function uploadFile(file) {
        if (window.confirm("Your old dialog image will be replace ?")) {

            const fd = new FormData()
            fd.append('file', file)

            const res = await hitAxios({
                path: "/api/admin/replace_dialog",
                post: true,
                admin: true,
                obj: fd
            })
            if (res.data.success) {
                getWebPublic()
            }
        }
    }

    React.useEffect(() => {
        getWebPublic()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Typography>
                    Add dialog image
                </Typography>

                <IconButton component="label">
                    <input
                        type='file'
                        hidden
                        accept='.png, .jpeg, .jpg'
                        onChange={(e) => {
                            const files = e.target.files;
                            for (let i = 0; i < files.length; i++) {
                                uploadFile(files[i]);
                            }
                        }}
                    />
                    <AddCircleOutlineRounded />
                </IconButton>

                <CardMedia
                    src={`/media/${web?.dialog_image}`}
                    component={'img'}
                />
            </Stack>
        </div>
    )
}

export default DialogImg