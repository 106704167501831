import { Close } from '@mui/icons-material'
import { Box, Container, Dialog, Divider, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const RuleComp = ({ state, setState, data }) => {

    const rowss = [{
        name: "1",
        commission: "0.50%",
        amount: "0",
        rate: "30"
    },
    {
        name: "2",
        commission: "0.65%",
        amount: "0",
        rate: "40"
    }, {
        name: "3",
        commission: "0.75%",
        amount: "0",
        rate: "50"
    }, {
        name: "4",
        commission: "1.00%",
        amount: "0",
        rate: "60"
    }, {
        name: "5",
        commission: "1.50%",
        amount: "0",
        rate: "70"
    }]

    return (
        <Dialog fullScreen open={state.ruleDialog}>
            <Container maxWidth='sm'>
                <Box bgcolor={'action.hover'} p={2}>
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, ruleDialog: false })}>
                            <Close />
                        </IconButton>
                        <Typography>
                            {data.close}
                        </Typography>
                    </Stack>

                </Box>


                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Box
                            sx={{ background: "linear-gradient(90deg, rgba(40,50,157,0.29037552521008403) 0%, rgba(203,16,224,0.1951374299719888) 100%)" }}
                            p={2} borderRadius={2} bgcolor={'lightblue'}>
                            <Stack direction={'column'} spacing={2}>
                                <Typography fontWeight={600} variant='h6'>
                                    {data.leDes}
                                </Typography>
                                <Divider />

                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes1}
                                    </Typography>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes11}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes2}
                                    </Typography>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes22}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes3}
                                    </Typography>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes33}
                                    </Typography>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes4}
                                    </Typography>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes44}
                                    </Typography>
                                </Stack>


                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes5}
                                    </Typography>
                                    <Typography variant='caption' fontWeight={600} >
                                        {data.lDes55}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box
                            bgcolor={'action.hover'}
                            p={2} borderRadius={2} >

                            <Stack direction={'column'} spacing={2}>
                                <Typography fontWeight={600} variant='h6'>
                                    {data.level}
                                </Typography>
                                <Divider />


                                <TableContainer >
                                    <Table size='small' aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{data.level}</TableCell>
                                                <TableCell align="right">{data.commission}</TableCell>
                                                <TableCell align="right">{data.amount}</TableCell>

                                                <TableCell align="right">{data.rate}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowss.map((row, key) => (
                                                <TableRow
                                                    key={key}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.commission}</TableCell>
                                                    <TableCell align="right">{row.amount}</TableCell>

                                                    <TableCell align="right">{row.rate}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </Box>


                    </Stack>

                </Box>
            </Container>
        </Dialog>
    )
}

export default RuleComp