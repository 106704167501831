import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { GlobalContext } from '../../../context/GlobalContext';
import { TranslateContext } from '../../../context/TranslateContext';
import { Grid, Box, Button } from '@mui/material'
import moment from 'moment';

const WithdrawComp = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [wit, setWit] = React.useState([])

    async function getData() {
        const res = await hitAxios({
            path: "/api/admin/get_withdrawal_all",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setWit(res.data.data)
        }
    }

    async function changeStatus(status, id) {
        const res = await hitAxios({
            path: "/api/admin/change_withdraw_status",
            post: true,
            admin: true,
            obj: {
                status,
                id
            }
        })
        if (res.data.success) {
            getData()
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                {wit ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...wit].reverse()}
                            columns={[
                                {
                                    headerName: "Exchange", field: 'exchange', flex: 1
                                },
                                {
                                    headerName: "Wallet address", field: 'wallet_address', flex: 1
                                },
                                {
                                    headerName: "Name", field: 'name', flex: 1
                                },
                                {
                                    headerName: "Phone", field: 'phone', flex: 1
                                },
                                {
                                    headerName: "Status", field: 'status', flex: 1
                                },
                                {
                                    headerName: "Withdraw Amount", field: 'withdrawal_amount', flex: 1
                                },
                                {
                                    headerName: "User name", field: 'userData', flex: 1, renderCell: row => {
                                        return (
                                            row?.row?.userData ? JSON.parse(row?.row?.userData)?.username : ""
                                        )
                                    }
                                },
                                {
                                    headerName: "Created", field: 'createdAt', flex: 1, renderCell: row => {
                                        return (
                                            moment(row.row?.createdAt).format("DD MM YYYY | hh:mmA")
                                        )
                                    }
                                },
                                {
                                    headerName: "Accept", field: 'accept', flex: 1, renderCell: row => {
                                        return (
                                            <Button
                                                onClick={() => changeStatus("APPROVED", row.row?.id)}
                                                size='small' variant='contained' color='success' >
                                                Accept
                                            </Button>
                                        )
                                    }
                                },
                                {
                                    headerName: "Reject", field: 'reject', flex: 1, renderCell: row => {
                                        return (
                                            <Button
                                                onClick={() => changeStatus("REJECT", row.row?.id)}
                                                size='small' variant='contained' color='error' >
                                                Reject
                                            </Button>
                                        )
                                    }
                                }
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </div>
    )
}

export default WithdrawComp