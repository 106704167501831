import { Cottage, EventSeat, Feed, FilterFrames, Person } from '@mui/icons-material'
import { BottomNavigation, BottomNavigationAction, Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BottomTab = ({ data, value, setValue }) => {
    const history = useHistory()
    const [index, setIndex] = React.useState(0)
    return (
        <Stack alignItems={'center'} >
            <BottomNavigation
                showLabels
                value={index}
                onChange={(event, newValue) => {
                    const selectedPath = event.currentTarget.getAttribute('data-path');
                    history.push(`/user?page=${selectedPath}`)
                    setValue(selectedPath);
                    setIndex(newValue)
                }}
                sx={{
                    pt: 2,
                    pb: 2,
                    bgcolor: "#F1F1F1",
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    width: '100%',
                    maxWidth: 'sm'
                }}
            >
                <BottomNavigationAction label={data.home} icon={< Cottage />} data-path="home" />
                <BottomNavigationAction label={data.information} icon={<Feed />} data-path="information" />
                <BottomNavigationAction label={data.reservation} icon={<EventSeat />} data-path="reservation" />
                <BottomNavigationAction label={data.orders} icon={<FilterFrames />} data-path="orders" />
                <BottomNavigationAction label={data.user} icon={<Person />} data-path="profile" />
            </BottomNavigation>
        </Stack>
    )
}

export default BottomTab