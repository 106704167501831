import { Box, CardMedia, Stack, Typography, Card, Zoom } from '@mui/material'
import React from 'react'

const Other = ({ title, des, img }) => {
    return (
        <div>
            <Box p={2} >
                <Stack direction={'column'} spacing={2}>
                    <Typography fontWeight={600} variant='h4' >
                        {title}
                    </Typography>

                    <Zoom in={true} timeout={1000}>
                        <CardMedia
                            component='img'
                            src={`/assets/${img}`}
                            sx={{ height: 200, width: '100%', borderRadius: 2 }}
                        />
                    </Zoom>

                    <Zoom in={true} timeout={1000}>
                        <Box p={2} borderRadius={2} bgcolor={'action.hover'} >
                            <Typography variant='body2' >
                                {des}
                            </Typography>
                        </Box>
                    </Zoom>
                </Stack>
            </Box>
        </div>
    )
}

export default Other