import React from 'react'
import { Dialog, Box, Stack, IconButton, Typography, CardMedia, Container } from '@mui/material'
import { Close } from '@mui/icons-material'

const DialogOpen = ({ open, state, setState }) => {
    return (
        <Dialog fullScreen open={state.dialog}>
            <Container maxWidth='sm'>
                <Box p={2} bgcolor={'action.hover'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <IconButton onClick={() => setState({ ...state, dialog: false })} >
                            <Close />
                        </IconButton>
                        <Typography fontWeight={600}>
                            {state?.dialogData?.title}
                        </Typography>
                    </Stack>
                </Box>

                <Box minHeight={'100vh'} mt={2}>
                    <Stack direction={'column'} spacing={2}>
                        <CardMedia
                            component={'img'}
                            src={`/media/${state?.dialogData?.image}`}
                            sx={{ height: '35vh', borderRadius: 2 }}
                        />

                        <Typography>
                            {state?.dialogData?.description}
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </Dialog>
    )
}

export default DialogOpen