import { Close } from '@mui/icons-material'
import { Box, Button, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { GlobalContext } from '../../../context/GlobalContext'

const Widthdraw = ({ data, state, setState }) => {
    const [da, setDa] = React.useState([])
    const [user, setUser] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)
    const [wi, setWi] = React.useState({
        exchange: "Binance"
    })

    async function getUser() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }

    async function getWithData() {
        const res = await hitAxios({
            path: "/api/user/get_withdrawal_history",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setDa(res.data.data)
        }
    }

    async function addRequest() {
        const res = await hitAxios({
            path: "/api/user/withdraw_amount",
            post: true,
            admin: false,
            obj: wi
        })
        if (res.data.success) {
            setWi(({
                exchange: "Binance",
                wallet_address: "",
                name: "",
                phone: "",
                withdrawal_amount: "",
                withdrawal_password: ""
            }))
            getWithData()
        }
    }

    React.useEffect(() => {
        getUser()
        getWithData()
    }, [])

    return (
        <Dialog
            fullScreen
            open={state.widthdraweDialog}
            onClose={() => setState({ ...state, widthdraweDialog: false })}
        >
            <Container maxWidth="sm">
                <Box p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, widthdraweDialog: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.walletDetals}
                        </Typography>
                    </Stack>
                </Box>

                <Box mt={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                value={wi.exchange}
                                onChange={(e) => setWi({ ...wi, exchange: e.target.value })}
                                fullWidth
                                label={data.exchange}
                            />
                            <TextFieldNew
                                value={wi.wallet_address}
                                onChange={(e) => setWi({ ...wi, wallet_address: e.target.value })}
                                fullWidth
                                label={data.walletAddress}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                value={wi.name}
                                onChange={(e) => setWi({ ...wi, name: e.target.value })}
                                fullWidth
                                label={data.name}
                            />
                            <TextFieldNew
                                value={wi.phone}
                                onChange={(e) => setWi({ ...wi, phone: e.target.value })}
                                fullWidth
                                label={data.phone}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                value={wi.withdrawal_amount}
                                onChange={(e) => setWi({ ...wi, withdrawal_amount: e.target.value })}
                                fullWidth
                                label={data.widthDrawrAmount}
                            />
                            <TextFieldNew
                                value={wi.withdrawal_password}
                                onChange={(e) => setWi({ ...wi, withdrawal_password: e.target.value })}
                                fullWidth
                                label={data.widthrawPas}
                            />
                        </Stack>


                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>
                                {data.balance}: ${user?.balance}
                            </Typography>

                            <Typography>
                                {data.minimumwithdrawal}
                            </Typography>
                        </Stack>

                        <Button
                            onClick={addRequest}
                            variant='contained'>
                            {data.submit}
                        </Button>

                        <Box borderRadius={2} bgcolor={'action.hover'} p={2}>
                            <Typography variant='body2'>
                                {data.descl}
                            </Typography>
                        </Box>

                        <Typography>
                            {data.withDrawalHistory}
                        </Typography>

                        {da.map((i, key) => {
                            return (
                                <Box key={key} borderRadius={2} bgcolor={'action.hover'} p={1}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography>
                                            {data.amount}: ${i?.withdrawal_amount}
                                        </Typography>

                                        <Box border={1} p={1}>
                                            <Typography variant='body2'>
                                                {i?.status}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Container>
        </Dialog>
    )
}

export default Widthdraw