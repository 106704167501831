import { CloudUploadOutlined } from '@mui/icons-material'
import { Box, CardMedia, Dialog, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'

const AssetManagement = ({ data, hitAxios }) => {
    const [hotel, setHotel] = React.useState([])
    const [state, setState] = React.useState({

    })

    async function getHotels() {
        const res = await hitAxios({
            path: "/api/admin/get_hotels_home",
            post: false,
            admin: false,
        })
        if (res.data.success) {
            setHotel(res.data.data)
        }
    }

    async function addHotel(file) {
        const title = prompt(data.hotelTitle)
        const des = prompt(data.hotelDes)
        let type

        if (window.confirm(data.writeType)) {
            type = "FEATURED"
        } else {
            type = "POPULAR"
        }

        if (!title || !des) {
            return alert(data.hotelTitleAndDes)
        }

        const fd = new FormData()
        fd.append('file', file)
        fd.append('title', title)
        fd.append('description', des)
        fd.append('type', type)

        const res = await hitAxios({
            path: "/api/admin/add_home_hotel",
            post: true,
            admin: true,
            obj: fd
        })
        if (res.data.success) {
            window.location.reload()
        }
    }

    async function delHotel(id) {
        if (window.confirm("are you sure to delete this ?")) {
            const res = await hitAxios({
                path: "/api/admin/del_home_hotel",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getHotels()
            }
        }
    }

    React.useEffect(() => {
        getHotels()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                <Box p={2} bgcolor={'white'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography fontWeight={600} >
                            {data.addHomeHotel}
                        </Typography>


                        <Tooltip title={data.uploadImage} >
                            <IconButton component='label' >
                                <input
                                    onChange={(e) => addHotel(e.target.files[0])}
                                    accept='.png, .jpeg, .jpg'
                                    hidden
                                    type='file'
                                />
                                <CloudUploadOutlined />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>


                <Box mt={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography>
                            Hotel list
                        </Typography>

                        <Stack
                            sx={{ overflowX: 'scroll' }}
                            direction={'row'} spacing={2}>
                            {hotel.map((i, key) => {
                                return (
                                    <CardMedia
                                        onClick={() => delHotel(i?.id)}
                                        component={'img'}
                                        sx={{ height: 100, width: 100, cursor: "pointer" }}
                                        src={`/media/${i?.image}`}
                                        key={key}
                                    />
                                )
                            })}
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </div>
    )
}

export default AssetManagement