import React from 'react'
import { TranslateContext } from '../../../context/TranslateContext'
import { GlobalContext } from '../../../context/GlobalContext'
import { Autocomplete, Button, Stack, Switch, TextField, Typography } from '@mui/material'
import TextFieldNew from '../../../components/TextFieldNew'
import AnList from './AnList'

const AnnouncementComp = () => {
    const { data } = React.useContext(TranslateContext)
    const [an, setAn] = React.useState([])
    const [state, setState] = React.useState({
        showAll: false
    })
    const { hitAxios } = React.useContext(GlobalContext)
    const [user, setUser] = React.useState([])

    async function getAllUsers() {
        const res = await hitAxios({
            path: "/api/admin/get_all_users",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }

    async function getAnnoun() {
        const res = await hitAxios({
            path: "/api/admin/get_announcement",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setAn(res.data.data)
        }
    }

    async function addAnnoun() {
        const res = await hitAxios({
            path: "/api/admin/add_announcement",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getAnnoun()
            setState({
                showAll: true,
                title: "",
                des: ""
            })
        }
    }

    React.useEffect(() => {
        getAllUsers()
        getAnnoun()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextFieldNew
                    onChange={(e) => setState({ ...state, title: e.target.value })}
                    label="Enter title"
                    fullWidth
                    value={state.title}
                />
                <TextFieldNew
                    onChange={(e) => setState({ ...state, des: e.target.value })}
                    label="Enter Description"
                    multiline
                    rows={2}
                    fullWidth
                    value={state.des}
                />

                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Typography>
                        For specif user?
                    </Typography>
                    <Switch
                        checked={state.showAll}
                        onChange={(e) => setState({ ...state, showAll: e.target.checked })}
                    />
                </Stack>

                {state.showAll &&
                    <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={user.map((i) => {
                            return {
                                ...i,
                                label: i?.username
                            }
                        })}
                        onChange={(e, user) => setState({ ...state, user: user })}
                        renderInput={(params) => <TextField
                            fullWidth {...params} label="Users List" />}
                    />}

                <Button
                    onClick={addAnnoun}
                    sx={{ borderRadius: 2, boxShadow: 0 }}
                    variant='contained'>
                    add as announcement
                </Button>

                <AnList
                    getAnnoun={getAnnoun}
                    data={data}
                    hitAxios={hitAxios}
                    an={an}
                />
            </Stack>
        </div>
    )
}

export default AnnouncementComp