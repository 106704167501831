import { CheckCircle, CloudQueue } from '@mui/icons-material'
import { Box, Button, CardMedia, CircularProgress, Dialog, Stack, Typography, Zoom } from '@mui/material'
import React from 'react'
import OneClickDialog from './oneClick/OneClickDialog'
import { GlobalContext } from '../../../context/GlobalContext'
import RuleComp from '../../user/options/RuleComp'

const OneClickBooking = ({ data, dialog, userDash, getDash, scrollToTop }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [simu, setSimu] = React.useState("")
    const [state, setState] = React.useState({
        dialog: false,
        hotelData: {},
        force: false
    })

    async function getHotel(force) {
        const res = await hitAxios({
            path: `/api/user/get_one_task${force ? '?force=true' : ''}`,
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        scrollToTop()
        setSimu("")
        if (res.data.success) {
            setState({ ...state, dialog: true, hotelData: res.data.data, force: force ? true : false })
        } else {
            setTimeout(() => {
                getDash()
            }, 2000);
        }
    }

    React.useEffect(() => {
        if (dialog) {
            getHotel("froce")
        }
    }, [])

    function handleSimu() {
        setSimu("ON")
        setTimeout(() => {
            setSimu(1)
        }, 800);
        setTimeout(() => {
            setSimu(2)
        }, 1600);
        setTimeout(() => {
            setSimu(3)
        }, 2400);
        setTimeout(() => {
            setSimu(4)
        }, 3200);
        setTimeout(() => {
            getHotel()
        }, 4000);
    }

    return (
        <div>
            <Box
                mb={2}
                borderRadius={2}
                bgcolor={'#019cde'} p={2}>
                <Stack alignItems={'center'} direction={'row'} spacing={3}>
                    <CloudQueue sx={{ height: 40, width: 40, color: 'white' }} />
                    <Typography color={'white'} fontWeight={'bold'} >
                        {data.welcomeToBook}
                    </Typography>
                </Stack>
            </Box>
            <CardMedia
                src='/assets/istockphoto-119926339-612x612.jpg'
                component={'img'}
                sx={{ height: 300, borderRadius: 2 }}
            />
            <Box mt={1}>
                <Button
                    onClick={handleSimu} variant='contained' fullWidth sx={{ borderRadius: 2, boxShadow: 0 }} >
                    {data.oneClickBooking}
                </Button>
            </Box>

            <Dialog
                fullWidth
                open={simu ? true : false}
                PaperProps={{
                    style: {
                        borderRadius: 10,
                        backgroundColor: "rgba(44, 64, 57, 0.42)",
                        backdropFilter: "blur(3px)",
                    }
                }}
            >
                <Box color={'white'} p={2}>
                    <Stack alignItems={'center'} direction={'column'} spacing={1}>
                        <Box borderRadius={2} p={2}>
                            <Stack alignItems={'center'} direction={'column'} spacing={1}>
                                <CircularProgress color='inherit' />
                                <Typography fontWeight={'bold'} align='center'>
                                    {data.fullSec}
                                </Typography>
                            </Stack>
                        </Box>
                        {parseInt(simu) > 0 &&
                            <Zoom in={parseInt(simu) > 0 ? true : false} timeout={1000} >
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <CheckCircle sx={{ color: "#70ED72" }} />
                                    <Typography>
                                        {data.syncingLatestNews}
                                    </Typography>
                                </Stack>
                            </Zoom>}

                        {parseInt(simu) > 1 &&
                            <Zoom in={parseInt(simu) > 0 ? true : false} timeout={1000} >
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <CheckCircle sx={{ color: "#70ED72" }} />
                                    <Typography>
                                        {data.checkHotelInfo}
                                    </Typography>
                                </Stack>
                            </Zoom>}

                        {parseInt(simu) > 2 &&
                            <Zoom in={parseInt(simu) > 0 ? true : false} timeout={1000} >
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <CheckCircle sx={{ color: "#70ED72" }} />
                                    <Typography>
                                        {data.matchingHotel}
                                    </Typography>
                                </Stack>
                            </Zoom>}

                        {parseInt(simu) > 3 &&
                            <Zoom in={parseInt(simu) > 0 ? true : false} timeout={500} >
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <CheckCircle sx={{ color: "#70ED72" }} />
                                    <Typography>
                                        {data.theoptbeing}
                                    </Typography>
                                </Stack>
                            </Zoom>}
                    </Stack>
                </Box>
            </Dialog>

            <OneClickDialog
                scrollToTop={scrollToTop}
                getDash={getDash}
                hitAxios={hitAxios}
                data={data}
                state={state}
                setSimu={setSimu}
                setState={setState}
            />

            <Box bgcolor={'white'} mt={2}>
                <Typography
                    onClick={() => setState({ ...state, ruleDialog: true })}
                    sx={{
                        p: 1,
                        cursor: "pointer"
                    }} variant='h4'>
                    {data.rules}
                </Typography>

                <CardMedia
                    src='/assets/hotel3.jpg'
                    component={'img'}
                    sx={{ height: 300, borderRadius: 2 }}
                />
            </Box>

            <RuleComp
                state={state}
                setState={setState}
                data={data}
            />
        </div>
    )
}

export default OneClickBooking