import { ArrowRightOutlined, CopyAll } from '@mui/icons-material'
import { Avatar, Box, Card, CardMedia, Chip, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { getMe } from '../../utils/requestFunction.js'
import { GlobalContext } from '../../context/GlobalContext'
import MainComp from './options/MainComp.js'
import SupportPage from '../../SupportPage.js'

const UserProfile = ({ data }) => {
    const [set, setSet] = React.useState({})
    const [user, setUser] = React.useState("")
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({

    })

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }



    async function getUser() {
        const res = await getMe(hitAxios)
        if (res.success) {
            setUser(res.data)
        }
    }

    React.useEffect(() => {
        getUser()
        getSett()
    }, [])

    return (
        user &&
        <Box>

            <MainComp
                data={data}
                state={state}
                setState={setState}
            />
            <Container disableGutters maxWidth='sm'>
                <Box >
                    <Box sx={{ bgcolor: (t) => t.palette.primary.main }} p={5} />

                    <Stack right={0} left={0} position={'absolute'} top={15} direction={'column'} spacing={2}>
                        <Typography fontWeight={'bold'} align='center' color={'white'}>
                            {data.user}
                        </Typography>

                        <Stack >
                            <Container maxWidth='sm'>
                                <Box pl={2} pr={2} pb={2}>
                                    <Box sx={{ boxShadow: "10px 0px 10px 0px rgba(0, 0, 0, 0.15)" }} borderRadius={2} bgcolor={'white'} p={2}>
                                        <Stack direction={'column'} spacing={3}>
                                            <Stack spacing={'10%'} direction={'row'}>
                                                <Box pt={0.6} >
                                                    <Avatar>
                                                        {user?.username?.slice(0, 1)?.toUpperCase()}
                                                    </Avatar>
                                                </Box>

                                                <Stack direction={'column'}>
                                                    <Typography>{user?.username}</Typography>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                        <Typography variant='caption' color={'gray'} >{data.inviteCode}: {user?.my_refer_code}</Typography>
                                                        <IconButton size='small' >
                                                            <CopyAll sx={{ height: 18, width: 18 }} />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <SupportPage
                                                data={data}
                                                set={set}
                                            />

                                            <Stack justifyContent={'space-between'} direction={'row'} spacing={2}>
                                                <Stack
                                                    onClick={() => {
                                                        CON?.setData({ ...CON.data, supportPage: true })
                                                    }}
                                                    sx={{ cursor: 'pointer' }}
                                                    alignItems={'center'} direction={'row'} spacing={1}>
                                                    <CardMedia
                                                        src='/assets/recharge.png'
                                                        component={'img'}
                                                        sx={{ height: "100%", width: 40 }}
                                                    />
                                                    <Typography>
                                                        {data.deposite}
                                                    </Typography>
                                                </Stack>

                                                <Stack
                                                    onClick={() => setState({ ...state, widthdraweDialog: true })}
                                                    sx={{ cursor: 'pointer' }}
                                                    alignItems={'center'} direction={'row'} spacing={1}>
                                                    <CardMedia
                                                        src='/assets/withdraw.png'
                                                        component={'img'}
                                                        sx={{ height: "100%", width: 40 }}
                                                    />
                                                    <Typography>
                                                        {data.withdraw}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Box>


                                    <Box mt={2} bgcolor={'#333333'} p={2} borderRadius={2}>
                                        <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} spacing={2}>
                                            <CardMedia
                                                src='/assets/VIP.gif'
                                                component={'img'}
                                                sx={{
                                                    height: "100%",
                                                    width: 50
                                                }}
                                            />

                                            <Stack direction={'column'}>
                                                <Typography color={'#f4e5c5'} variant='body2'>
                                                    {data.yourCurLevelIs}: {user?.user_type}
                                                </Typography>
                                                <Typography color={'#f4e5c5'} variant='caption' >
                                                    {data.memberClassMore}
                                                </Typography>
                                            </Stack>

                                            <Chip
                                                onClick={() => setState({ ...state, levelDialog: true })}
                                                color='info'
                                                label={data.check}
                                            />
                                        </Stack>
                                    </Box>

                                    <Box sx={{ boxShadow: "10px 0px 10px 0px rgba(0, 0, 0, 0.15)" }} mt={2} borderRadius={2} bgcolor={'white'} p={2}>
                                        <Typography fontWeight={'bold'} >
                                            {data.commonFunction}
                                        </Typography>

                                        <Box p={2} >
                                            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                                <Stack
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setState({ ...state, levelDialog: true })}
                                                    direction={'column'} alignItems={'center'}>
                                                    <CardMedia
                                                        src='/assets/vip_2.png'
                                                        component={'img'}
                                                        sx={{
                                                            height: "100%",
                                                            width: 50
                                                        }}
                                                    />
                                                    <Typography variant='caption' align='center' >
                                                        {data.memLevel}
                                                    </Typography>
                                                </Stack>

                                                <Stack
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setState({ ...state, accountDialog: true })}
                                                    direction={'column'} alignItems={'center'}>
                                                    <CardMedia
                                                        src='/assets/acc_icon.png'
                                                        component={'img'}
                                                        sx={{
                                                            height: "100%",
                                                            width: 50
                                                        }}
                                                    />
                                                    <Typography variant='caption' align='center' >
                                                        {data.acDetails}
                                                    </Typography>
                                                </Stack>

                                                <Stack direction={'column'} alignItems={'center'}>
                                                    <CardMedia
                                                        onClick={() => {
                                                            CON?.setData({ ...CON.data, supportPage: true })
                                                        }}
                                                        src='/assets/kf.png'
                                                        component={'img'}
                                                        sx={{
                                                            height: "100%",
                                                            width: 50,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                    <Typography variant='caption' align='center' >
                                                        {data.cusService}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Box>

                                    <Box sx={{ boxShadow: "10px 0px 10px 0px rgba(0, 0, 0, 0.15)", mb: 10 }} mt={2} borderRadius={2} bgcolor={'white'} p={2}>
                                        <Stack direction={'column'} spacing={1}>
                                            <Stack
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => setState({ ...state, loginChange: true })}
                                                direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography variant='body2' >
                                                    {data.changePass}
                                                </Typography>
                                                <ArrowRightOutlined />
                                            </Stack>
                                            <Divider />

                                            <Stack
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => setState({ ...state, withChange: true })}
                                                direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography variant='body2' >
                                                    {data.changeWidhPass}
                                                </Typography>
                                                <ArrowRightOutlined />
                                            </Stack>
                                            <Divider />

                                            <Stack
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => setState({ ...state, helpDialog: true })}
                                                direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography variant='body2' >
                                                    {data.help}
                                                </Typography>
                                                <ArrowRightOutlined />
                                            </Stack>
                                            <Divider />


                                            <Stack
                                                onClick={() => {
                                                    if (window.confirm(data.areYourSureLogout)) {
                                                        localStorage.removeItem('dialog')
                                                        localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                                                        window.location.reload()
                                                    }
                                                }}
                                                sx={{ cursor: "pointer" }}
                                                direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography variant='body2' >
                                                    {data.exit}
                                                </Typography>
                                                <ArrowRightOutlined />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Container>
                        </Stack>
                    </Stack>
                </Box>
            </Container>
        </Box>
    )
}

export default UserProfile