import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Box, Container, Dialog, Divider, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import moment from 'moment'

const ShowAnnoun = ({ data, hitAxios, state, setState }) => {
    const [an, setAn] = React.useState([])
    const CON = React.useContext(GlobalContext)

    async function getAnnoun() {
        const res = await hitAxios({
            path: "/api/user/get_announcement",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setAn(res.data.data)
        }
    }

    React.useEffect(() => {
        getAnnoun()
    }, [])

    return (
        <Dialog fullScreen open={state.anoun} >
            <Container maxWidth='sm'>
                <Box bgcolor={'action.hover'} p={2}>
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => {
                            setState({ ...state, anoun: false })
                        }}>
                            <Close />
                        </IconButton>
                        <Typography>
                            {data.announcement}
                        </Typography>
                    </Stack>
                </Box>
                <Stack mt={2} direction={'column'} spacing={2}>
                    {CON.data.loading &&
                        <LinearProgress />}

                    {an.map((i, key) => {
                        return (
                            <Box key={key} borderRadius={2} bgcolor={'action.hover'} p={2}>
                                <Stack direction={'column'} spacing={1}>
                                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontWeight={600}>
                                            {i?.title}
                                        </Typography>
                                        <Typography variant='caption'>
                                            {moment(i?.createdAt).format("DD MM YY | hh:mmA")}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                    <Typography>
                                        {i?.description}
                                    </Typography>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Container>
        </Dialog>
    )
}

export default ShowAnnoun