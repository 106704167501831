import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Alert, Snackbar, Box, Backdrop, CircularProgress, CardMedia, Container } from '@mui/material'
import { GlobalContext } from './context/GlobalContext'
import PublicRoute from './routing/PublicRoute'
import { TranslateContext } from './context/TranslateContext';
import axios from 'axios';
import FrontEnd from './screens/FrontEnd'
import AdminRoute from './routing/AdminRoute'
import UserRoute from './routing/UserRoute'
import { Helmet } from "react-helmet";
import Home from './screens/home/Home'
import AdminPanel from './admin/AdminPanel'
import AdminLogin from './admin/AdminLogin'
import UserLogin from './screens/UserLogin'
import UserProfile from './screens/user/UserProfile'
import Error404 from './Error404'


const HomeMainPage = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const LANGUAGE = React.useContext(TranslateContext)
    const [web, setWeb] = React.useState("")

    async function getWeb() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        localStorage.setItem('language', 'English')
        const code = localStorage.getItem('language')
        // const localcode = local.slice(0, local.indexOf('.'))
        if (code) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${code}`)
                .then((res) => {
                    if (res.data.notfound) {
                        localStorage.removeItem('language')
                        alert("Refreshing translation...")
                        window.location.reload()
                        return
                    }
                    LANGUAGE.setData({ ...res.data.data })
                })
                .catch((err) => console.log(err))
        } else {
            localStorage.setItem('language', 'English')
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-all-translation-name`,)
                .then((lan) => {
                    localStorage.setItem('language', lan.data.data[0]?.replace(".json", ""))
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${lan.data.data[0]?.replace(".json", "")}`)
                        .then((res) => {
                            LANGUAGE.setData({ ...res.data.data })
                        })
                        .catch((err) => console.log(err))
                })
        }
        getWeb()
    }, [])


    return (
        <Box sx={{ position: 'relative' }} color='text.primary' bgcolor='background.default'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(4px)" }}
                open={GLOBAL_CONTEXT.data.loading}
            >

                <CircularProgress />
            </Backdrop>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={GLOBAL_CONTEXT.data.snack} autoHideDuration={4000} >
                <Alert severity={GLOBAL_CONTEXT.data.snack_success ? 'success' : 'error'}>{GLOBAL_CONTEXT.data.snack_msg}</Alert>
            </Snackbar>

            <div style={{
                opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
                pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial"
            }}>
                <Switch>
                    <PublicRoute path='/user/login' exact component={UserLogin} />
                    <UserRoute path='/user' exact component={FrontEnd} />
                    <UserRoute path='/' exact component={FrontEnd} />
                    <AdminRoute path='/admin' exact component={AdminPanel} />
                    <PublicRoute path='/admin/login' exact component={AdminLogin} />

                    <PublicRoute path='*' component={Error404} />
                </Switch>
            </div>
        </Box>
    )
}

export default HomeMainPage

