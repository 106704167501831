import React from 'react'
import { TranslateContext } from '../context/TranslateContext'
import { Box, Container, Dialog, CardMedia, Button } from '@mui/material'
import BottomTab from './BottomTab'
import Home from './home/Home'
import UserProfile from './user/UserProfile'
import Info from './information/Info'
import Reservation from './reservation/Reservation'
import Orders from './orders/Orders'
import { GlobalContext } from '../context/GlobalContext'
import SupportPage from '../SupportPage'

const FrontEnd = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [value, setValue] = React.useState('home');
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const [web, setWeb] = React.useState({})
    let foo = params.get('page');
    const [set, setSet] = React.useState({})

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }


    React.useEffect(() => {
        setValue(foo || 'home')
    }, [foo])

    const bottomTab = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
    }

    React.useEffect(() => {
        getWebPublic()
        getSett()
    }, [])

    return (
        <Box bgcolor={'gray'} >


            <SupportPage
                data={data}
                set={set}
            />


            <Dialog
                fullWidth
                open={web?.dialog_image && !localStorage.getItem('dialog')}>

                <CardMedia
                    component={'img'}
                    src={`/media//${web?.dialog_image}`}
                />
                <Button
                    onClick={() => {
                        setWeb({ ...web, dialog_image: "" })
                        localStorage.setItem('dialog', "yes")
                    }}
                    variant='contained'>
                    {data.close}
                </Button>
            </Dialog>

            <Container disableGutters maxWidth='sm'>
                <Box id='shit' overflow={'auto'} height={'100vh'} bgcolor={'#F9F9F9'} >
                    <Box pb={10} >
                        {value == 'home' &&
                            <Home
                                data={data}
                            />}

                        {value == 'reservation' &&
                            <Reservation
                                data={data}
                            />}

                        {value == 'orders' &&
                            <Orders
                                data={data}
                            />}

                        {value == 'profile' &&
                            <UserProfile
                                data={data}
                            />}
                        {value == 'information' &&
                            <Info
                                data={data}
                            />}
                    </Box>
                    <Box sx={bottomTab} >
                        <BottomTab
                            setValue={setValue}
                            value={value}
                            data={data}
                        />
                    </Box>
                </Box>
            </Container>

        </Box >
    )
}

export default FrontEnd