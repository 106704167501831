import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

const TextFieldNew = ({ startIcon, endIcon, size, fontSize, bgColor, shrink, ...props }) => {
    return (
        <TextField
            {...props}
            size={size || 'small'}
            InputProps={{
                style: {
                    borderRadius: 10,
                    fontSize: fontSize && fontSize,
                    backgroundColor: bgColor && bgColor
                },
                endAdornment: endIcon && (
                    <InputAdornment position='end'>{endIcon}</InputAdornment>
                ),
                startAdornment: startIcon && (
                    <InputAdornment position='start'>{startIcon}</InputAdornment>
                )
            }}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};

export default TextFieldNew;
