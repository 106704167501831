import { Close, Help } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import HelpComp from './HelpComp'
import RuleComp from '../../user/options/RuleComp'

const CheckLevel = ({ data, state, setState, user }) => {
    return (
        <Dialog
            fullScreen
            open={state.levelDialog}
            onClose={() => setState({ ...state, levelDialog: false })}
        >
            <Container maxWidth="sm">
                <Box mb={2} p={2} bgcolor={'action.hover'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setState({ ...state, levelDialog: false })} >
                            <Close />
                        </IconButton>

                        <Typography>
                            {data.levelDetails}
                        </Typography>
                    </Stack>
                </Box>

                <Stack direction={'column'} spacing={2}>
                    <Box bgcolor={'action.hover'} borderRadius={2} p={2}>
                        <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                            <Stack direction={'column'}>
                                <Typography variant='h6'>
                                    {data.yourCurrentLevel}
                                </Typography>

                                <Typography variant='h3'>
                                    {user?.user_type}
                                </Typography>
                            </Stack>
                            <CardMedia
                                sx={{
                                    height: 150,
                                    width: 150,
                                    cursor: 'pointer'
                                }}
                                component={'img'}
                                src='/assets/1665412350380.png'
                            />
                        </Stack>

                        <RuleComp
                            state={state}
                            setState={setState}
                            data={data}
                        />

                        <Button
                            onClick={() => setState({ ...state, ruleDialog: true })}
                            color='info'
                            variant='contained'
                            startIcon={<Help />}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 2
                            }}
                            size='small' >
                            {data.help}
                        </Button>
                    </Box>

                    <Typography fontWeight={600} >
                        {data.intro}
                    </Typography>

                    <Typography variant='body2'>
                        {data.introDes}
                    </Typography>
                </Stack>

            </Container>
        </Dialog>
    )
}

export default CheckLevel