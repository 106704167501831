import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomeMainPage from './HomeMainPage'
import { GlobalProvider } from './context/GlobalContext';
import { TranslateProvider } from './context/TranslateContext';
import GetTrans from './utils/GetTrans';
import { FlowProvider } from './context/FlowContext';

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });


export default function ToggleColorMode() {
  const { trans } = GetTrans()

  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'light' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            'Outfit'
          ].join(','),
        },
        palette: {
          mode,
          primary: {
            main: trans ? trans.color_theme : '#22a454'
          }
        },
      }),
    [mode, trans],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <GlobalProvider>
        <TranslateProvider>
          <FlowProvider>
            <ThemeProvider theme={theme}>
              <HomeMainPage />
            </ThemeProvider>
          </FlowProvider>
        </TranslateProvider>
      </GlobalProvider>
    </ColorModeContext.Provider>
  );
}