import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { GlobalContext } from '../../../context/GlobalContext'

const Commission = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({

    })

    async function getHandle() {
        const res = await hitAxios({
            path: "/api/admin/get_commission",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setState({ ...state, ...res.data.data })
        }
    }

    async function updateHandle() {
        const res = await hitAxios({
            path: "/api/admin/update_commission",
            post: true,
            admin: true,
            obj: state
        })
    }

    React.useEffect(() => {
        getHandle()
    }, [])

    return (
        state.id &&
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextFieldNew
                    type="number"
                    value={state.normal}
                    onChange={(e) => setState({ ...state, normal: e.target.value })}
                    label="NORMAL"
                />
                <TextFieldNew
                    type="number"
                    value={state.vip1}
                    onChange={(e) => setState({ ...state, vip1: e.target.value })}
                    label="VIP1"
                />
                <TextFieldNew
                    type="number"
                    value={state.vip2}
                    onChange={(e) => setState({ ...state, vip2: e.target.value })}
                    label="VIP2"
                />
                <TextFieldNew
                    type="number"
                    value={state.vip3}
                    onChange={(e) => setState({ ...state, vip3: e.target.value })}
                    label="VIP3"
                />
                <TextFieldNew
                    type="number"
                    value={state.vip4}
                    onChange={(e) => setState({ ...state, vip4: e.target.value })}
                    label="VIP4"
                />

                <TextFieldNew
                    type="number"
                    value={state.premium}
                    onChange={(e) => setState({ ...state, premium: e.target.value })}
                    label="PREMIUM"
                />

                <TextFieldNew
                    type="number"
                    startIcon={<Typography>%</Typography>}
                    value={state.refer_commission}
                    onChange={(e) => setState({ ...state, refer_commission: e.target.value })}
                    label="Refer commission"
                />

                <Button
                    onClick={updateHandle}
                    variant='contained' >
                    Submit
                </Button>
            </Stack>
        </div>
    )
}

export default Commission