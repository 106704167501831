import React from 'react'
import SliderComp from '../home/components/SliderComp'
import { GlobalContext } from '../../context/GlobalContext'
import PopularHotel from './components/PopularHotel'
import FeaturedHotels from './components/FeaturedHotels'
import { Divider } from '@mui/material'
import DialogOpen from './components/DialogOpen'

const Info = ({ data }) => {
    const [slider, setSlider] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    const [hotels, setHotels] = React.useState([])
    const [state, setState] = React.useState({
        dialog: false,
        dialogData: {}
    })

    async function getSlider() {
        const res = await hitAxios({
            path: "/api/admin/get_assets",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setSlider(res.data.data?.home_slider ? JSON.parse(res.data.data?.home_slider) : [])
        }
    }

    async function getHotels() {
        const res = await hitAxios({
            path: "/api/admin/get_hotels_home",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setHotels(res.data.data)
        }
    }

    React.useEffect(() => {
        getSlider()
        getHotels()
    }, [])

    return (
        <div>
            <SliderComp
                data={data}
                slider={slider}
            />

            <DialogOpen
                data={data}
                state={state}
                setState={setState}
            />

            <PopularHotel
                state={state}
                setState={setState}
                hotels={hotels.filter(i => i.type === 'POPULAR')}
                data={data}
            />
            <Divider />

            <FeaturedHotels
                state={state}
                setState={setState}
                hotels={hotels.filter(i => i.type === 'FEATURED')}
                data={data}
            />
        </div>
    )
}

export default Info