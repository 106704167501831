import { Box, Button, CardMedia, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../../../components/TextFieldNew'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material'
import { GlobalContext } from '../../../../../context/GlobalContext'
import { LoadingButton } from '@mui/lab'


const AddTask = ({ data, hitAxios, dialogData }) => {
    const CON = React.useContext(GlobalContext)
    const [selected, setSelected] = React.useState([])
    const [hotels, setHotels] = React.useState([])
    const [state, setState] = React.useState({
        totalTask: "loading...",
        hotelData: []
    })

    async function addTask() {
        const res = await hitAxios({
            path: "/api/admin/add_task_admin",
            post: true,
            admin: true,
            obj: {
                hotels: selected,
                uid: dialogData?.uid
            }
        })
    }


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Stack alignItems={'flex-end'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            {selected.length > 0 && <Typography color={'gray'} fontWeight={600} >({selected.length})</Typography>}
                            <LoadingButton
                                loading={CON.loading}
                                onClick={addTask}
                                disabled={selected.length < 1 ? true : false}>
                                Add as task
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Box>
            </GridToolbarContainer>
        );
    }

    async function getHotelsAdded() {
        const res = await hitAxios({
            path: "/api/admin/get_hotel_added",
            post: true,
            admin: true,
            obj: { section: dialogData?.user_type }
        })
        if (res.data.success) {
            setHotels(res.data.data)
        }
    }

    React.useEffect(() => {
        getHotelsAdded()
    }, [])

    const handleSelectionChange = (newSelection) => {
        setSelected(newSelection);
    };



    return (
        <Box >
            <Stack direction={'column'} spacing={2}>
                <Typography>
                    Select Hotels you want to add in task
                </Typography>


                <Box borderRadius={4} bgcolor={'action.hover'} >
                    {hotels ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.hotel_id}
                                rows={[...hotels].reverse()}
                                columns={[
                                    {
                                        headerName: "Image", field: 'image', flex: 1, renderCell: row => {
                                            return (
                                                <CardMedia
                                                    sx={{ height: "100%", width: 50 }}
                                                    component={'img'}
                                                    src={`/media/${row.row.image}`}
                                                />
                                            )
                                        }
                                    },
                                    { headerName: "Name", field: 'name', flex: 1 },
                                    { headerName: "Amount", field: 'amount', flex: 1 },
                                    { headerName: "Description", field: 'description', flex: 1 },
                                    { headerName: "Hotel ID", field: 'hotel_id', flex: 1 },
                                    { headerName: "Premium", field: 'premium', flex: 1 },
                                ]}
                                onRowSelectionModelChange={handleSelectionChange}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={true}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Stack>
        </Box>
    )
}

export default AddTask