import SliderComp from './components/SliderComp'
import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import HeroButton from './components/HeroButton'
import Other from './components/Other'
import ChangeTranslate from './components/ChangeTranslate'
import { GlobalContext } from '../../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Home = ({ data }) => {
    const [set, setSet] = React.useState({})
    const [slider, setSlider] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    const history = useHistory()

    async function getSlider() {
        const res = await hitAxios({
            path: "/api/admin/get_assets",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setSlider(res.data.data?.home_slider ? JSON.parse(res.data.data?.home_slider) : [])
        }
    }

    async function getSett() {
        const res = await hitAxios({
            path: "/api/admin/get_set",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setSet(res.data.data)
        }
    }


    React.useEffect(() => {
        getSlider()
        getSett()
    }, [])
    return (
        <Box>
            <Box position={'relative'}>
                <SliderComp
                    slider={slider}
                    data={data}
                />


                <Stack
                    top={0}
                    right={0}
                    left={0}

                    sx={{ bgcolor: "rgba(0, 0, 0, 0.33)", position: "absolute" }}
                    alignItems={'center'} direction={'row'} justifyContent={'space-between'}>

                    <Box p={1}>
                        <Typography
                            onClick={() => history.push("/")}
                            sx={{ cursor: "pointer" }}
                            color={'white'}>
                            {data.theBokExp}
                        </Typography>
                    </Box>

                    <Box >
                        <ChangeTranslate
                            data={data}
                        />
                    </Box>
                </Stack>



                <Box sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0
                }} >
                    <HeroButton
                        set={set}
                        data={data}
                    />
                </Box>
            </Box>

            <Other
                title={"About"}
                des={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet quam sed quam feugiat luctus.
                Duis tristique facilisis risus in semper. Donec bibendum turpis vitae massa tincidunt tincidunt.
                Nunc feugiat et nisi ac maximus`}
                data={data}
                img={'istockphoto-119926339-612x612.jpg'}
            />
            <Other
                title={"Service"}
                des={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet quam sed quam feugiat luctus.
                Duis tristique facilisis risus in semper. Donec bibendum turpis vitae massa tincidunt tincidunt.
                Nunc feugiat et nisi ac maximus`}
                data={data}
                img={'photo-1625244724120-1fd1d34d00f6.avif'}
            />
        </Box>
    )
}

export default Home