import { Translate } from '@mui/icons-material'
import { Box, IconButton, Dialog, Stack, Typography, Button } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'

const ChangeTranslate = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [lang, setLang] = React.useState([])
    const [state, setState] = React.useState({
        dialog: false
    })

    async function getAllTrans() {
        const res = await hitAxios({
            path: "/api/web/get-all-translation-name",
            post: false,
            admin: false
        })
        if (res.data.success) {
            setLang(res.data.data)
        }
    }

    React.useEffect(() => {
        getAllTrans()
    }, [])

    return (
        <div>
            <Box borderRadius={"50%"} bgcolor={"rgba(255, 255, 255, 0.38)"} p={0.5} m={1}>
                <IconButton onClick={() => setState({ ...state, dialog: true })}>
                    <Translate />
                </IconButton>
            </Box>

            <Dialog maxWidth='xs' fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography fontWeight={600}>
                            {data.clickToChangeLang}
                        </Typography>
                        {lang.map((i, key) => {
                            return (
                                <Button
                                    onClick={() => {
                                        localStorage.setItem('language', i.replace(".json", ""))
                                        window.location.reload()
                                    }}
                                    key={key} variant='contained' size='small'>
                                    {i?.replace(".json", "")}
                                </Button>
                            )
                        })}
                    </Stack>
                </Box>
            </Dialog>
        </div>
    )
}

export default ChangeTranslate