import React from 'react'
import { TranslateContext } from '../context/TranslateContext'
import { GlobalContext } from '../context/GlobalContext'
import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import AssetManagement from './components/AssetManagement';
import AddHotels from './components/add-hotels/AddHotels';
import Users from './components/users/Users';
import Commission from './components/commission/Commission';
import AnnouncementComp from './components/announcements/AnnouncementComp';
import DialogImg from './components/dialog-image/DialogImg';
import Settings from './components/settings/Settings';
import WithdrawComp from './components/withdraw/WithdrawComp';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminPanel = () => {
    const [state, setState] = React.useState({})
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Container maxWidth='lg'>
                <Box mt={2} >
                    <Typography variant='h6' fontWeight={600} >
                        {data.helloAdmin}
                    </Typography>
                </Box>


                <Box mt={'1rem'} sx={{ width: '100%', bgcolor: "#F9F9F9", borderRadius: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Asset Management" {...a11yProps(0)} />
                            <Tab label={data.manageHotel} {...a11yProps(1)} />
                            <Tab label={data.users} {...a11yProps(2)} />
                            <Tab label="Commission" {...a11yProps(3)} />
                            <Tab label="Announcements" {...a11yProps(4)} />
                            <Tab label="Dialog Image" {...a11yProps(5)} />
                            <Tab label="Logo" {...a11yProps(6)} />
                            <Tab label="Withdraw request" {...a11yProps(7)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <AssetManagement
                            hitAxios={hitAxios}
                            data={data}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <AddHotels
                            hitAxios={hitAxios}
                            data={data}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Users
                            value={value} index={2}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Commission
                            value={value} index={3}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <AnnouncementComp
                            value={value} index={4}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <DialogImg
                            value={value} index={5}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={6}>
                        <Settings
                            value={value} index={6}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={7}>
                        <WithdrawComp
                            value={value} index={7}
                        />
                    </CustomTabPanel>
                </Box>

            </Container>
        </div>
    )
}

export default AdminPanel