import { Delete, DeleteOutline } from '@mui/icons-material'
import { Box, Button, CardMedia, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const HotelList = ({ hotels, hitAxios, data, getHotels }) => {

    async function delHotel(id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/admin/del_hotel",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getHotels()
            }
        }
    }
    return (
        <div>
            <Box mt={3}>
                <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                    {hotels ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...hotels].reverse()}
                                columns={[
                                    {
                                        headerName: "Image", field: 'image', flex: 1, renderCell: row => {
                                            return (
                                                <CardMedia
                                                    sx={{ height: "100%", width: 50 }}
                                                    component={'img'}
                                                    src={`/media/${row.row.image}`}
                                                />
                                            )
                                        }
                                    },
                                    {
                                        headerName: "hotel Id", field: 'hotel_id', flex: 1
                                    },
                                    {
                                        headerName: "Name", field: 'name', flex: 1
                                    },
                                    {
                                        headerName: "Amount USD", field: 'amount', flex: 1
                                    },
                                    {
                                        headerName: "Premium", field: 'premium', flex: 1
                                    },
                                    {
                                        headerName: "Section", field: 'section', flex: 1
                                    },
                                    {
                                        headerName: "Delete", field: 'del', flex: 1, renderCell: row => {
                                            return (
                                                <IconButton color='error' onClick={() => delHotel(row.row.id)} >
                                                    <Delete />
                                                </IconButton>
                                            )
                                        }
                                    }
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                // components={{
                                //     Toolbar: CustomToolbar
                                // }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>
        </div>
    )
}

export default HotelList