import { Box, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import AddTask from './AddTask';
import ViewTaskReport from './ViewTaskReport';
import Transaction from './Transaction';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TabComp = ({ data, hitAxios, dialogData }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: "action.hover" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Add Tasks" {...a11yProps(0)} />
                    <Tab label="View Task report" {...a11yProps(1)} />
                    <Tab label="Transaction" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <Box bgcolor={'action.hover'}>
                <CustomTabPanel value={value} index={0}>
                    <AddTask
                        dialogData={dialogData}
                        data={data}
                        hitAxios={hitAxios}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ViewTaskReport
                        dialogData={dialogData}
                        data={data}
                        hitAxios={hitAxios}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Transaction
                        dialogData={dialogData}
                        data={data}
                        hitAxios={hitAxios}
                    />
                </CustomTabPanel>
            </Box>
        </div>
    )
}

export default TabComp